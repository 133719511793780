import React, { Component } from "react"
import { Alert } from "reactstrap"
import { ContextMenu, MenuItem, SubMenu } from "react-contextmenu-v2"
import Moment from "moment"
import { extendMoment } from "moment-range"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import EventUtils from "@dashboard/event/EventUtils"

import BackdropDialog from "@common/input/button/BackdropDialog"
import EnergyService from "@dashboard/energy/EnergyService"

import ProjectUtils from "@common/utils/ProjectUtils"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const moment = extendMoment(Moment)
const { t } = i18n

class ProjectsTableProjectMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Whether to display the meter read dialog
      showMeterReadDialog: false,
      // The path of the device used for the reading
      meterReadSourceId: null,
      // Status info message for the meter reading dialog
      meterReadStatus: null,
    }
  }

  displayFetchMessage(date) {
    this.setState(() => ({
      meterReadStatus: {
        text: `${t("energy.messages.fetch_reading", { date: date.format() })}`,
        color: "info",
      },
    }))
  }

  displayReadingMessage(date, wattHours) {
    this.setState(() => ({
      meterReadStatus: {
        text: `${t("energy.messages.reading_on", { date: date.format() })}: ${EnergyUtils.formatWattHoursToPreferrence(wattHours)}`,
        color: "success",
      },
    }))
  }

  displayNoReadings() {
    this.setState(() => ({
      meterReadStatus: {
        text: `${t("energy.messages.no_readings", { meterReadSourceId: this.state.meterReadSourceId })}`,
        color: "warning",
      },
    }))
  }

  displayErrorMessage(e) {
    this.setState(() => ({
      meterReadStatus: {
        text: `${t("energy.errorMsgs.failed_to_fetch")}: ${e.toString()}`,
        color: "danger",
      },
    }))
  }

  renderMeterDialog() {
    return (
      <BackdropDialog
        type={"date"}
        body={
          <Alert color={"info"}>
            <Icon icon="info" />
            {t("energy.labels.select_date")}: {this.props.project.timezone}
          </Alert>
        }
        title={this.state.meterReadSourceId + `: ${t("labels.meter_reading")}`}
        dateProps={{ inputPlaceholder: t("energy.labels.select_date") }}
        isOpen={this.state.showMeterReadDialog}
        onCancel={() =>
          this.setState({
            showMeterReadDialog: false,
            meterReadStatus: null,
          })
        }
        onConfirm={async (date) => {
          try {
            if (moment(date) > moment(new Date())) {
              this.displayErrorMessage(t("energy.errorMsgs.wrong_date"))
              return
            }

            const site = ProjectUtils.getSiteCode(this.state.meterReadSourceId)
            const timezone = EventUtils.getTimeZoneForProject(this.props.project, site)
            const tzDate = moment(date).tz(timezone)

            this.displayFetchMessage(tzDate)

            const range = moment.range(ProjectUtils.getProductionStartDate([this.props.project]), tzDate.toDate())
            const response = await EnergyService.getEnergyReadings(range)

            if (response.projects && response.projects[this.props.project.code]) {
              this.displayReadingMessage(tzDate, response.projects[this.props.project.code].sites[site].generation)
            } else {
              this.displayNoReadings()
            }
          } catch (e) {
            this.displayErrorMessage(e)
          }
        }}
        displayTextColor={this.state.meterReadStatus && this.state.meterReadStatus.color}
        displayText={this.state.meterReadStatus && this.state.meterReadStatus.text}
      />
    )
  }

  render() {
    let project = this.props.project
    let projectStatus = this.props.projectStatus

    if (projectStatus) {
      return (
        <>
          {this.state.showMeterReadDialog && this.renderMeterDialog()}
          <ContextMenu id={"project-row-" + project.code} key={project.code}>
            {this.getStatusMessages(projectStatus)}

            {Object.values(projectStatus.sites).map((site) => {
              if (site) {
                return (
                  <span className={site.status} key={site.code}>
                    <SubMenu title={site.code + "-" + site.name} hoverDelay={50}>
                      {this.getStatusMessages(site)}

                      {Object.values(site.systems).map((system) => {
                        return (
                          <span className={system.status} key={system.code}>
                            <SubMenu title={system.code + "-" + system.name} hoverDelay={50}>
                              {this.getStatusMessages(system)}
                              {this.getNodes(system)}
                            </SubMenu>
                          </span>
                        )
                      })}

                      {this.getNodes(site, Object.values(site.systems))}
                    </SubMenu>
                  </span>
                )
              } else {
                return null
              }
            })}

            {this.getNodes(projectStatus, Object.values(projectStatus.sites))}
          </ContextMenu>
        </>
      )
    }
    return null
  }

  getStatusMessages(nodeStatus) {
    if (nodeStatus.causes && nodeStatus.causes.length > 0) {
      return (
        <React.Fragment>
          {nodeStatus.causes.map((cause) => {
            return (
              <MenuItem key={cause.message}>
                <div className={"menu-" + cause.status}>{this.getCauseMessage(cause)}</div>
              </MenuItem>
            )
          })}
        </React.Fragment>
      )
    }
  }

  getCauseMessage(cause) {
    switch (cause.type) {
      case "consumption":
        return `${t("energy.messages.consumption_higher_than_expected")}`
      case "generation":
        return `${t("energy.messages.generation_lower_than_expected")}`
      case "connectivity":
        return `${t("energy.messages.no_connectivity_in_last_24_hours")}`
      case "reading":
        return `${t("energy.messages.no_reading_in_last_24_hours")}`
      default:
        return cause.message
    }
  }

  getNodes(asset, children) {
    let nodesStatus = asset.nodesStatus
    if (children) {
      nodesStatus = EnergyUtils.filterOutChildNodes(asset, children)
    }

    if (nodesStatus.length > 0) {
      return (
        <span className="asset-nodes">
          {children ? <MenuItem divider /> : null}

          {nodesStatus.map((nodeStatus) => {
            return (
              <span
                className={nodeStatus.status}
                key={asset.code + "-" + nodeStatus.nodeId + "-" + nodeStatus.sourceId}
              >
                <SubMenu title={nodeStatus.sourceId + " (" + nodeStatus.nodeId + ")"} hoverDelay={50}>
                  {this.getStatusMessages(nodeStatus)}
                  {this.props.groups.includes("power-user") ? (
                    <MenuItem
                      data={{ status: nodeStatus, projectId: this.props.project.code }}
                      onClick={this.openNodeTerminal}
                    >
                      {t("energy.menuItems.solar_node_ssh", { nodeId: nodeStatus.nodeId })}
                    </MenuItem>
                  ) : null}
                  <MenuItem data={nodeStatus} onClick={this.openNodeDashboard}>
                    {t("energy.menuItems.solar_node_ssh_dashboard", { nodeId: nodeStatus.nodeId })}
                  </MenuItem>
                  {EnergyUtils.getDeviceType(nodeStatus) === "GEN" && (
                    <MenuItem
                      data={nodeStatus}
                      onClick={() => {
                        this.setState(() => ({
                          showMeterReadDialog: true,
                          meterReadSourceId: nodeStatus.sourceId,
                        }))
                      }}
                    >
                      {t("energy.menuItems.solar_node_ssh_meterRead", { nodeId: nodeStatus.nodeId })}
                    </MenuItem>
                  )}
                </SubMenu>
              </span>
            )
          })}
        </span>
      )
    } else if (children && children.length > 0) {
      return null
    } else {
      return (
        <span className="no-nodes">
          <MenuItem>
            <i>{t("energy.errorMsgs.no_solar_network_configured")}</i>
          </MenuItem>
        </span>
      )
    }
  }

  openNodeTerminal(e, data) {
    window.open(
      `/terminal?nodeId=${data.status.nodeId}&sourceId=${data.status.sourceId}&projectId=${data.projectId}`,
      "ssh-" + data.status.nodeId,
    )
  }

  openNodeDashboard(e, data) {
    window.open(
      process.env.REACT_APP_SOLARNETWORK_HOST + "/node-dashboard/login?nodeId=" + data.nodeId,
      "solar-dashboard-" + data.nodeId,
    )
  }
}

export default ProjectsTableProjectMenu
