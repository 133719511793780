import React from "react"
import moment from "moment"
import { create, all } from "mathjs"

import EcosuiteComponent, { Loading, Error, EcosuiteComponentError, Message } from "@common/EcosuiteComponent"

import { SideBarSection, LAYOUT_CONTENT_BOTTOM_RIGHT } from "@common/module/EcosuiteView"

import EnergyUtils from "../EnergyUtils"

import ProjectsTable from "./project/ProjectsTable"
import {
  InstantaneousGraph,
  ConsumptionGenerationPieGraph,
  SelfConsumptionPieGraph,
  EnergyConsumedPieGraph,
} from "../graphs/EnergyGraphs"
import EnergyView from "./EnergyView"

import Emissions from "./info/Emissions"
import InfoWidgetsSidebar from "@common/display/infoWidgets/infoWidgetsSideBar"

import EnergyDashboardGraphs from "../graphs/EnergyDashboardGraphs"
import i18n from "src/i18n"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

const math = create(all)
const { t } = i18n

/**
 * Expects the following props:
 *
 * projects - list of the projects that should be displayed
 * projectsStatus - list of the status of projects

 * readings - map of project readings
 * lastMonthsEnergyReadings - map of last months project readings

 * datums - map of project datums
 * datumsFilter - the filter for the project datums

 * predictedConsumption - the forecast consumption datums
 * expectedGeneration - the expected generation datums
 * predictedGeneration - the predicted generation datums

 * instantaneous - map of instantaneous project readings

 * selectProject={this.selectProject}

 * @extends EcogyContent
 */
export default class EnergyOverView extends EnergyView {
  constructor(props) {
    super(props)

    this.state = {
      layout: this.props.layout,
    }
  }

  getLayout() {
    return LAYOUT_CONTENT_BOTTOM_RIGHT
  }

  getInstantaneousTime() {
    if (this.isContentValid(this.props.instantaneous)) {
      return moment(this.props.instantaneous.timestamp).format("lll")
    }
    return null
  }

  renderMainView() {
    if (!this.props.projects) {
      return <Loading />
    } else if (this.props.projects.length < 1) {
      return <Message message={t("alertsInfo.no_filtered_projects")} />
    }
    return (
      <ProjectsTable
        range={this.props.range}
        groups={this.props.groups}
        projects={this.props.projects}
        projectsStatus={this.props.projectsStatus}
        readings={this.props.readings}
        datums={this.props.datums instanceof EcosuiteComponentError ? null : this.props.datums}
        consumptionCost={this.props.consumptionCost}
        predictedConsumption={this.props.predictedConsumption}
        expectedGeneration={this.props.expectedGeneration}
        predictedGeneration={this.props.predictedGeneration}
        lastMonthsEnergyReadings={this.props.lastMonthsEnergyReadings}
        selectProject={this.props.selectProject}
        showGeneration={this.props.showGeneration}
        showConsumption={this.props.showConsumption}
        showStorage={this.props.showStorage}
      />
    )
  }

  renderFooter() {
    return this.props.projects && <EnergyDashboardGraphs {...this.props} />
  }

  getReadingTotals(projects, readings) {
    if (this.isContentValid(projects) && this.isContentValid(readings)) {
      let generation = projects.reduce((total, project) => {
        return math.add(total, readings.projects[project.code].generation)
      }, 0)
      let consumption = projects.reduce((total, project) => {
        return math.add(total, readings.projects[project.code].consumption)
      }, 0)
      let exportEnergy = projects.reduce((total, project) => {
        return math.add(total, readings.projects[project.code].export)
      }, 0)
      let storage = projects.reduce((total, project) => {
        return math.add(total, readings.projects[project.code].storage)
      }, 0)
      let size = projects.reduce((total, project) => {
        return math.add(total, project.dcSize ? project.dcSize : 0)
      }, 0)
      return {
        generation: generation,
        consumption: consumption,
        export: exportEnergy,
        storage: storage,
        size: size,
      }
    }
    return null
  }

  widgetDefinitions() {
    let definedWidgets = []
    let projectsReadingTotals = this.isContentError(this.props.readings)
      ? this.props.readings
      : this.getReadingTotals(this.props.projects, this.props.readings)

    if (this.props.showGeneration) {
      definedWidgets["Last Months Generation"] = this.LastMonthsGeneration()
      definedWidgets["Emissions"] = this.GenEmissions()
      definedWidgets["Instantaneous KW"] = this.InstantaneousKW()
    }
    if (this.props.showConsumption) {
      definedWidgets["Energy Consumed By"] = this.EnergyConsumedBy(projectsReadingTotals)
      definedWidgets["Self Consumption"] = this.SelfConsumption(projectsReadingTotals)
    }
    if (this.props.showConsumption && this.props.showGeneration) {
      definedWidgets["Generation Vs Consumption"] = this.GenerationVsConsumption(projectsReadingTotals)
    }

    return definedWidgets
  }

  LastMonthsGeneration() {
    return (
      <React.Fragment>
        {this.props.lastMonthsEnergyReadings && (
          <UncontrolledTooltip target="last-months-generation">
            {t("energy.tooltips.last_months_generation")}
          </UncontrolledTooltip>
        )}
        <SideBarSection
          title={t("energy.labels.last_months_generation")}
          content={
            <LastMonthsGeneration
              lastMonthsEnergyReadings={this.props.lastMonthsEnergyReadings}
              id="last-months-generation"
            />
          }
        />
      </React.Fragment>
    )
  }

  GenEmissions() {
    return <Emissions readings={this.props.readings} sourceType="generation" />
  }

  InstantaneousKW() {
    return (
      <SideBarSection
        title={t("energy.labels.instantaneous")}
        subtitle={this.getInstantaneousTime()}
        content={
          <InstantaneousGraph
            instantaneous={this.props.instantaneous}
            datums={this.props.recentDatums}
            projects={this.props.projects}
          />
        }
      />
    )
  }

  EnergyConsumedBy(projectsReadingTotals) {
    return (
      <SideBarSection
        title={t("energy.labels.energy_consumed")}
        content={<EnergyConsumedPieGraph readings={projectsReadingTotals} />}
      />
    )
  }

  SelfConsumption(projectsReadingTotals) {
    return (
      <SideBarSection
        title={t("energy.labels.self_consumption")}
        content={<SelfConsumptionPieGraph readings={projectsReadingTotals} />}
      />
    )
  }

  GenerationVsConsumption(projectsReadingTotals) {
    return (
      <SideBarSection
        title={t("energy.labels.generation_vs_consumption")}
        content={<ConsumptionGenerationPieGraph readings={projectsReadingTotals} />}
      />
    )
  }

  renderSiderBar() {
    return (
      <React.Fragment>
        <InfoWidgetsSidebar ecosuiteVeiw="ams" userId={this.props.userId} widgets={this.widgetDefinitions()} />
      </React.Fragment>
    )
  }
}

class LastMonthsGeneration extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.lastMonthsEnergyReadings)) {
      return <Error error={this.props.lastMonthsEnergyReadings.getError()} />
    }
    if (
      this.isContentValid(this.props.lastMonthsEnergyReadings) &&
      this.props.lastMonthsEnergyReadings.generation !== undefined
    ) {
      return (
        <div className="sidebar-heading" id={this.props.id && this.props.id}>
          {EnergyUtils.displayWattHours(this.props.lastMonthsEnergyReadings.generation)}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
