import React, { Component } from "react"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"

const { t } = i18n
export default class SourceTableTooltips extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-source-node">
          The ID of the SolarNetwork node.
          <p className="tooltip-detail">
            <span className="text-ok">Green</span>: no issues
          </p>
          <p className="tooltip-detail">
            <span className="text-warning">Orange</span>: connectivity issues
          </p>
          <p className="tooltip-detail">
            <span className="text-error">Red</span>: issues need to be resolved
          </p>
        </UncontrolledTooltip> */}
        <UncontrolledTooltip
          placement="bottom"
          modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
          target="col-source-id"
        >
          {t("energy.tooltips.solar_network_id_source")}
          <p className="tooltip-detail">
            <span className="text-ok">{t("energy.energyStatus.green")}</span>: {t("energy.messages.no_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-warning">{t("energy.energyStatus.orange")}</span>:{" "}
            {t("energy.messages.connectivity_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-error">{t("energy.energyStatus.red")}</span>:{" "}
            {t("energy.messages.issues_need_tobe_resolved")}
          </p>
        </UncontrolledTooltip>

        <UncontrolledTooltip
          placement="bottom"
          modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
          target="col-source-latest-reading"
        >
          {t("energy.tooltips.latest_reading_recorded")}
        </UncontrolledTooltip>

        <UncontrolledTooltip
          placement="bottom"
          modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
          target="col-source-last-hour"
        >
          {t("energy.tooltips.reading_for_last_hour")}
        </UncontrolledTooltip>
        <UncontrolledTooltip
          placement="bottom"
          modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
          target="col-source-last-24"
        >
          {t("energy.tooltips.reading_for_last_24")}
        </UncontrolledTooltip>
        {this.props.showGeneration ? (
          <UncontrolledTooltip
            placement="bottom"
            modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
            target="col-source-dc-size"
          >
            {t("energy.tooltips.dc_generation")}
          </UncontrolledTooltip>
        ) : null}
        {this.props.showGeneration ? (
          <UncontrolledTooltip
            placement="bottom"
            modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
            target="col-source-ac-size"
          >
            {t("energy.tooltips.ac_generation")}
          </UncontrolledTooltip>
        ) : null}
        {this.props.showConsumption ? (
          <UncontrolledTooltip
            placement="bottom"
            modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
            target="col-source-expected-peak"
          >
            {t("energy.tooltips.consumption")}
          </UncontrolledTooltip>
        ) : null}
        {this.props.system ? (
          <React.Fragment>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-peak"
            >
              {t("energy.tooltips.observed_peak")}
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-peak-percent"
            >
              {t("energy.tooltips.peak_vs_device_size")}
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-start"
            >
              {t("energy.tooltips.start_reading")}
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-end"
            >
              {t("energy.tooltips.end_reading")}
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-reading"
            >
              {t("energy.tooltips.actual_reading")}
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-expected"
            >
              {t("energy.tooltips.expected_reading")}
              <p className="tooltip-detail">{t("energy.tooltips.generation_calculation")}</p>
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-expected-percent"
            >
              {t("energy.tooltips.actual_vs_expected")}
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-predicted"
            >
              {t("energy.tooltips.forecast_reading")}
              <p className="tooltip-detail">{t("energy.tooltips.generation_calculated_with_forecast")}</p>
              <p className="tooltip-detail">{t("energy.tooltips.consumption_calculated_with_expected_daily")}</p>
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-predicted-percent"
            >
              {t("energy.tooltips.actual_vs_forecast")}
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
              target="col-source-ratio"
            >
              {t("energy.tooltips.actual_generation_normalised")}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }
}
