import React from "react"
import { Button, Alert } from "reactstrap"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"

import WidgetService from "@common/display/infoWidgets/WidgetService"
import Icon from "@common/display/Icon"
import TimedMessage from "@common/display/TimedMessage"
import i18n from "src/i18n"
import defaultWidgets from "@common/display/infoWidgets/DefaultWidgets"

const { t } = i18n

class UserWidgetMenu extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        applications: "null",
      },
    }
  }

  componentDidMount() {
    super.componentDidMount()
    WidgetService.getWidgetListSchema().then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
    WidgetService.getWidgetList(this.props.userId).then((data) => {
      if (data.widgets != null) {
        Logger.debug("List found!", data.widgets)
        let formData = { applications: data.widgets.Attributes }
        this.setStateIfMounted({ formData: formData })
      } else {
        this.setStateIfMounted({ formData: defaultWidgets })
      }
    })
  }

  editWidgets() {
    WidgetService.createWidgetList(this.props.userId, this.state.formData.applications)
      .then((data) => {
        let formData = { applications: data.widgets.Attributes }
        this.setStateIfMounted({ formData: formData, saving: false, success: true })
      })
      .catch((err) => {
        Logger.error(err)
        this.setState({ error: err, success: false, formData: this.state.formData, saving: false })
      })
  }

  resetWidgets() {
    this.setState({ formData: defaultWidgets }, () => {
      this.editWidgets()
    })
  }

  moveWidget(widget, modifier, appIndex) {
    if (
      widget.location + modifier < 0 ||
      widget.location + modifier >= this.state.formData.applications[appIndex].widgets.length
    ) {
      return
    }
    let widget2 = this.state.formData.applications[appIndex].widgets[widget.location + modifier]
    if (widget2 !== "null") {
      this.swapWidgetsLocation(widget, widget2, appIndex)
    }
  }

  findNearestByApplication(widget, direction) {
    let i = widget.location + direction

    while (i <= this.state.formData.widgets.length && i >= 0) {
      if (this.widgetsShareApplication(this.state.formData.widgets[i], widget)) {
        return this.state.formData.widgets[i]
      }
      i = i + direction
    }
    return "null"
  }

  swapWidgetsLocation(widget1, widget2, appIndex) {
    let newLocation1 = widget2.location
    let newLocation2 = widget1.location

    let newWidget1 = widget1
    newWidget1.location = newLocation1
    let newWidget2 = widget2
    newWidget2.location = newLocation2

    let newWidgets = []
    newWidgets.push(newWidget1)
    newWidgets.push(newWidget2)
    this.updateWidgets(newWidgets, appIndex)
  }

  flipWidgetOnState(index, appIndex) {
    let newWidget = this.state.formData.applications[appIndex].widgets[index]
    let value = newWidget.enabled
    if (value === true) {
      value = false
    } else {
      value = true
    }
    newWidget.enabled = value

    this.updateWidgets(newWidget, appIndex)
  }

  updateWidgets(newWidgets, appIndex) {
    //Using an array to update for the sake of effiency
    let newObject = this.state.formData

    if (Object.prototype.toString.call(newWidgets) === "[object Array]") {
      for (let i = 0; i < newWidgets.length; ++i) {
        newObject.applications[appIndex].widgets[newWidgets[i].location] = newWidgets[i]
      }
    } else {
      newObject.applications[appIndex].widgets[newWidgets.location] = newWidgets
    }

    this.setStateIfMounted({ formData: newObject })
  }

  renderWidget(widget, index, appIndex) {
    return (
      <li key={widget.name}>
        <h5>
          {widget.name}:{" "}
          <input type="checkbox" checked={widget.enabled} onChange={() => this.flipWidgetOnState(index, appIndex)} />
        </h5>
        <Button color="primary" onClick={() => this.moveWidget(widget, -1, appIndex)}>
          Move up
        </Button>
        <Button color="primary" onClick={() => this.moveWidget(widget, 1, appIndex)}>
          Move down
        </Button>
      </li>
    )
  }

  renderWidgetList(application) {
    let appIndex = this.findAppIndex(application)

    if (appIndex !== "null") {
      let widgetList = this.state.formData.applications[appIndex].widgets

      let listItems = []
      for (let i = 0; i < widgetList.length; ++i) {
        for (let j = i; j < widgetList.length; ++j) {
          let widget = widgetList[j]
          if (widget.location === i) {
            listItems.push(this.renderWidget(widgetList[j], j, appIndex))
            break
          }
        }
      }
      const title = application.charAt(0).toUpperCase() + application.slice(1)
      return (
        <ul style={({ display: "inline-block" }, { float: "left" })}>
          <h4>{title}</h4>
          <ul>{listItems}</ul>
        </ul>
      )
    } else {
      return
    }
  }

  findAppIndex(application) {
    for (let x in this.state.formData.applications) {
      if (this.state.formData.applications[x].name === application) {
        return x
      }
    }
    return "null"
  }

  renderContent() {
    if (this.state.saving) {
      return <Loading message={`${t("loadingMsg.updating_profile")}`} />
    }
    if (!this.state.formData) {
      return <Loading message={`${t("loadingMsg.loading_profile")}`} />
    }

    if (this.state.schema) {
      let error = this.getError()
      return (
        <div className="ecogy-form admin-tab-content-area">
          <div className="Admin">
            <div className="Admin-content" style={{ overflowY: "scroll", height: "80vh" }}>
              <Alert color="info" className="info">
                <Icon icon="info" />
                {t("notes.profile_changes")}
              </Alert>
              {this.state.success ? (
                <TimedMessage delay={8000}>
                  <Alert color="info">{t("messages.profile_updated")}</Alert>
                </TimedMessage>
              ) : null}

              {error ? <Alert color="danger">{error}</Alert> : null}
              <div>
                {this.renderWidgetList("ams")}
                {this.renderWidgetList("econode")}
              </div>
              <div className="Admin-footer">
                <Button size="sm" color="primary" type="submit" onClick={() => this.editWidgets()}>
                  Save
                </Button>
                <Button size="sm" color="secondary" type="submit" onClick={() => this.resetWidgets()}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return <Loading />
    }
  }

  getError() {
    if (this.state && this.state.error) {
      return this.state.error.message ? this.state.error.message : this.state.error
    }
    return null
  }
}

export default UserWidgetMenu
