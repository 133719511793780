import * as React from "react"
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormReset,
  UseFormSetValue,
  useFieldArray,
  useFormContext,
} from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import { PasswordField, TextField } from "./AmplifyTextFields"
import { energyUnits, Organization } from "../services/organization"
import LogoUpload from "./LogoUpload"
import { CheckboxField, SelectField } from "@aws-amplify/ui-react"
import "./dark.scss"
import i18n from "src/i18n"
import WithToolTip from "@common/display/ToolTip"
import { enableSharing } from "../services/organization"

const { t } = i18n
const translate = (k: string) => t(k) || ""

interface Props {
  remove?: any
  register?: any
  fields?: any
  control: Control<Omit<Organization, "id">, any>
  errors: FieldErrors<Omit<Organization, "id">>
  reset: UseFormReset<any>
  setValue: UseFormSetValue<Omit<Organization, "id">>
  organization: Organization | undefined
}

export default function OrganizationFormFields(props: Props) {
  const { control, errors, organization, setValue } = props

  const { fields, append, remove } = useFieldArray({
    control,
    name: "solarNetworkCredentials",
  })

  return (
    <>
      <h3 style={{ marginTop: "8px" }}>{translate("settings.orgPreferences.General")}</h3>
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            placeholder={translate("settings.orgPreferences.Organization Name")}
            label={translate("settings.orgPreferences.Organization Name*")}
            errorMessage="This field is required"
            inputStyles={{
              backgroundColor: "#FFFFFF",
            }}
            {...field}
            hasError={!!errors.name}
          />
        )}
      />

      <Controller
        name="slug"
        control={control}
        render={({ field }) => (
          <TextField
            placeholder="Domain"
            label={translate("settings.orgPreferences.Unique identifier (Slug)*")}
            {...field}
            errorMessage="This field is required"
            hasError={!!errors.slug}
          />
        )}
      />

      <Controller
        name="domain"
        control={control}
        render={({ field }) => (
          <TextField placeholder="Domain" label={translate("settings.orgPreferences.Domain")} {...field} />
        )}
      />

      <h3 style={{ marginTop: "16px" }}>{translate("settings.orgPreferences.SolarNetwork Credentials")}</h3>
      {fields.map((field, index) => (
        <div key={field.id}>
          <h4>Credential Set {index + 1}</h4>
          <Controller
            name={`solarNetworkCredentials.${index}.alias`}
            control={control}
            render={({ field }) => <TextField placeholder="Alias" label="Alias" {...field} />}
          />
          <Controller
            name={`solarNetworkCredentials.${index}.host`}
            control={control}
            render={({ field }) => <TextField placeholder="Host" label="Host" {...field} />}
          />
          <Controller
            name={`solarNetworkCredentials.${index}.sshHost`}
            control={control}
            render={({ field }) => <TextField placeholder="SSH Host" label="SSH Host" {...field} />}
          />
          <Controller
            name={`solarNetworkCredentials.${index}.wssHost`}
            control={control}
            render={({ field }) => <TextField placeholder="WSS Host" label="WSS Host" {...field} />}
          />
          <Controller
            name={`solarNetworkCredentials.${index}.token`}
            control={control}
            render={({ field }) => <TextField placeholder="Token" label="Token" {...field} />}
          />
          <Controller
            name={`solarNetworkCredentials.${index}.token`}
            control={control}
            render={({ field }) => <TextField placeholder="Token" label="Token" {...field} />}
          />
          <Controller
            name={`solarNetworkCredentials.${index}.secret`}
            control={control}
            render={({ field }) => <PasswordField placeholder="Secret" label="Secret" {...field} />}
          />
          <button type="button" onClick={() => remove(index)}>
            Remove Credential Set
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={() =>
          append({
            alias: "",
            host: "https://data.solarnetwork.net",
            sshHost: "ssh.solarnetwork.net:8443",
            wssHost: "wss://data.solarnetwork.net",
            secret: "",
            token: "",
          })
        }
      >
        Add SolarNetwork Credential Set
      </button>

      <h3 style={{ marginTop: "16px" }}>{translate("settings.orgPreferences.Settings")}</h3>

      <Controller
        name="settings.luckyOrangeSiteId"
        control={control}
        render={({ field }) => (
          <TextField
            placeholder="Site ID"
            label={translate("settings.orgPreferences.lucky_orange_site_id")}
            {...field}
          />
        )}
      />

      <label
        style={{
          fontSize: "16px",
          marginBottom: "0px",
        }}
      >
        {translate("settings.orgPreferences.Project Milestone Email Recipients")}
      </label>

      <Controller
        control={control}
        name="settings.projectMilestoneRecipients"
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <CreatableSelect
              options={[]}
              onChange={onChange}
              isMulti={true}
              onBlur={onBlur}
              value={value}
              name={name}
              ref={ref}
              styles={{ control: (baseStyles) => ({ ...baseStyles, marginBottom: "8px" }) }}
            />
          )
        }}
      />
      <Controller
        name="settings.preferredEnergyUnits"
        control={control}
        render={({ field }) => (
          <SelectField label={"Preferred energy units"} {...field}>
            <option value={energyUnits.Kilowatts}>
              {"Kilowatts"}
            </option>
            <option value={energyUnits.Megawatts}>
              {"Megawatts"}
            </option>
            <option value={energyUnits.Auto}>
              {"Auto"}
            </option>
          </SelectField>
        )}
      />
      <label
        style={{
          fontSize: "16px",
          marginBottom: "0px",
        }}
      >
        {translate("settings.orgPreferences.Organization Logo") + "(~ 3:1 aspect ratio)"}
      </label>
      <LogoUpload
        currentLogoUrl={organization?.settings?.logoUrl}
        onBlur={async (file) => {
          if (file) {
            const dataUrl: string = await new Promise((resolve) => {
              const reader = new FileReader()
              reader.onload = () => resolve(reader.result as string)
              reader.readAsDataURL(file)
            })
            setValue("settings.logoUrl", dataUrl, { shouldDirty: true })
          }
        }}
      />
    </>
  )
}

export function OrganizationDataSharingFeilds(props: Props) {
  const { control } = props

  return (
    <>
      <h3 style={{ marginTop: "8px" }}>{translate("settings.orgPreferences.Data sharing")}</h3>
      <Controller
        name="settings.isPublic"
        control={control}
        render={({ field: { onChange, value } }) => (
          <WithToolTip tooltip={translate("settings.orgPreferences.public_org_description")}>
            <CheckboxField
              label={translate("settings.orgPreferences.Public_organization")}
              checked={value}
              onChange={onChange}
              name="isPublic"
              value="yes"
            />
          </WithToolTip>
        )}
      />
      <Controller
        name="dataSharing.enableSharing"
        control={control}
        render={({ field }) => (
          <SelectField label={translate("settings.orgPreferences.Enable sharing")} {...field}>
            <option value={enableSharing.DoNotShareMyData}>
              {translate("settings.orgPreferences.Do not share my data")}
            </option>
            <option value={enableSharing.ShareMyDataButItMustBeAnonymized}>
              {translate("settings.orgPreferences.Share my data but it must be anonymized")}
            </option>
            <option value={enableSharing.ShareMyDataFully}>
              {translate("settings.orgPreferences.Share my data fully")}
            </option>
          </SelectField>
        )}
      />
      <Controller
        name="dataSharing.shareIfMonetizable"
        control={control}
        render={({ field: { onChange, value } }) => (
          <WithToolTip tooltip={translate("settings.orgPreferences.only_if_monetization_is_possible_description")}>
            <CheckboxField
              label={translate("settings.orgPreferences.only_if_monetization_is_possible")}
              checked={value}
              onChange={onChange}
              name="shareIfMonetizable"
              value="yes"
            />
          </WithToolTip>
        )}
      />
    </>
  )
}

export function OrganizationAdvancedFeilds(props: Props) {
  const { control, errors } = props

  return (
    <>
      <h3 style={{ marginTop: "8px" }}>{translate("settings.orgPreferences.Google Integration")}</h3>
      <Controller
        name="googleIntegration.serviceRequestTemplateId"
        control={control}
        render={({ field }) => (
          <TextField
            placeholder={translate("settings.orgPreferences.Service Request Template ID")}
            label={translate("settings.orgPreferences.Service Request Template ID")}
            {...field}
          />
        )}
      />
      <Controller
        name="googleIntegration.projectMilestoneDatesId"
        control={control}
        render={({ field }) => (
          <TextField
            placeholder={translate("settings.orgPreferences.Project Milestone Dates ID")}
            label={translate("settings.orgPreferences.Project Milestone Dates ID")}
            {...field}
          />
        )}
      />
      <Controller
        name="googleIntegration.checklistTemplateId"
        control={control}
        render={({ field }) => (
          <TextField
            placeholder={translate("settings.orgPreferences.Checklist Template ID")}
            label={translate("settings.orgPreferences.Checklist Template ID")}
            {...field}
          />
        )}
      />
      <Controller
        name="googleIntegration.googleDrivePrivateKey"
        control={control}
        render={({ field }) => (
          <PasswordField
            placeholder={translate("settings.orgPreferences.Google Drive Private Key")}
            label={translate("settings.orgPreferences.Google Drive Private Key")}
            {...field}
          />
        )}
      />
      <Controller
        name="googleIntegration.googleDriveClientEmail"
        control={control}
        render={({ field }) => (
          <TextField
            placeholder={translate("settings.orgPreferences.Google Drive Client Email")}
            label={translate("settings.orgPreferences.Google Drive Client Email")}
            {...field}
          />
        )}
      />

      <h3 style={{ marginTop: "16px" }}>{translate("settings.orgPreferences.Softledger")}</h3>

      <Controller
        name="softLedger.clientId"
        control={control}
        render={({ field }) => (
          <PasswordField
            placeholder={translate("settings.orgPreferences.softledger_client_id")}
            label={translate("settings.orgPreferences.softledger_client_id")}
            {...field}
          />
        )}
      />

      <Controller
        name="softLedger.clientSecret"
        control={control}
        render={({ field }) => (
          <PasswordField
            placeholder={translate("settings.orgPreferences.softledger_client_secret")}
            label={translate("settings.orgPreferences.softledger_client_secret")}
            {...field}
          />
        )}
      />

      <Controller
        name="softLedger.tenantUUID"
        control={control}
        render={({ field }) => (
          <PasswordField
            placeholder={translate("settings.orgPreferences.softledger_tenant_uuid")}
            label={translate("settings.orgPreferences.softledger_tenant_uuid")}
            {...field}
          />
        )}
      />

      <h3 style={{ marginTop: "16px" }}>{translate("settings.orgPreferences.Other")}</h3>
      <Controller
        name="settings.salesforceUrl"
        control={control}
        rules={{
          pattern: /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
        }}
        render={({ field }) => (
          <TextField
            placeholder="Salesforce URL"
            label="Salesforce URL"
            errorMessage="Please enter a valid url"
            hasError={!!errors.settings?.salesforceUrl}
            {...field}
          />
        )}
      />
      <Controller
        name="settings.qgisUrl"
        control={control}
        render={({ field }) => <TextField placeholder="QGIS URL" label="QGIS URL" {...field} />}
      />
      <Controller
        name="settings.slackXbobToken"
        control={control}
        render={({ field }) => <TextField placeholder="Slack Xbob Token" label="Slack Xbob Token" {...field} />}
      />
    </>
  )
}

interface extAPIFieldProps {
  index: number
  remove: any
}

const LocusEnergyFields = ({ index, remove }: extAPIFieldProps) => {
  const { control, register } = useFormContext()

  return (
    <>
      <Controller
        name={`energyAPIs.${index}.APIname`}
        render={({ field }) => <h4 style={{ marginTop: "16px", display: "inline-block" }}>{field.value} &nbsp;</h4>}
      />
      <button type="button" onClick={() => remove(index)}>
        Delete Api
      </button>
      <TextField {...register(`energyAPIs.${index}.clientId`)} label="Client Id" />
      <PasswordField {...register(`energyAPIs.${index}.clientSecret`)} label="Client Secret" />
      <TextField {...register(`energyAPIs.${index}.username`)} label="Username" />
      <PasswordField {...register(`energyAPIs.${index}.password`)} label="Password" />
      <TextField {...register(`energyAPIs.${index}.clientId`)} label="Client Id" />
      <PasswordField {...register(`energyAPIs.${index}.clientSecret`)} label="Client Secret" />
      <TextField {...register(`energyAPIs.${index}.username`)} label="Username" />
      <PasswordField {...register(`energyAPIs.${index}.password`)} label="Password" />

      <ComponentArrayField index={index} type={"meters"} title={"Meters"} />
      <ComponentArrayField index={index} type={"inverters"} title={"Inverters"} />
    </>
  )
}

interface ComponentArrayFieldProps {
  index: number
  type: string
  title: string
}

const ComponentArrayField = ({ index, type, title }: ComponentArrayFieldProps) => {
  const { control, register } = useFormContext()
  const {
    fields: children,
    append: appendChild,
    remove: removeChild,
  } = useFieldArray({
    name: `energyAPIs.${index}.${type}`,
  })

  return (
    <ul style={{ listStyleType: "none" }}>
      <h4 style={{ marginTop: "16px" }}>{title}</h4>
      {children.map((child, componentIndex) => (
        <li key={child.id}>
          <Controller
            name={`energyAPIs.${index}.${type}.${componentIndex}.name`}
            render={({ field }) => <h4 style={{ marginTop: "10px", display: "inline-block" }}>{field.value} &nbsp;</h4>}
          />
          <button
            type="button"
            onClick={() => removeChild(componentIndex)}
          >{`Remove ${title.substring(0, title.length - 1)}`}</button>
          <TextField
            placeholder="12345"
            {...register(`energyAPIs.${index}.${type}.${componentIndex}.sourceComponentId`)}
            label="Source Component Id"
          />
          <TextField
            placeholder="/PROJECT/SITE/SYSTEM/SOURCE"
            {...register(`energyAPIs.${index}.${type}.${componentIndex}.destinationSourceId`)}
            label="Destination Source Id"
          />
          <TextField
            placeholder="12345"
            {...register(`energyAPIs.${index}.${type}.${componentIndex}.sourceComponentId`)}
            label="Source Component Id"
          />
          <TextField
            placeholder="/PROJECT/SITE/SYSTEM/SOURCE"
            {...register(`energyAPIs.${index}.${type}.${componentIndex}.destinationSourceId`)}
            label="Destination Source Id"
          />
        </li>
      ))}
      <button
        type="button"
        onClick={() => appendChild({ name: children.length + 1 })}
      >{`Add ${title.substring(0, title.length - 1)}`}</button>
    </ul>
  )
}

export function OrganiztionEnergyApiFields(props: Props) {
  const { fields, register, remove } = props

  return (
    <ul style={{ listStyleType: "none" }}>
      {fields.map((field: FieldArrayWithId, index: number) => {
        return (
          <li key={field.id} {...register(`energyAPIs.${index}`)}>
            <LocusEnergyFields remove={remove} index={index} />
          </li>
        )
      })}
    </ul>
  )
}
