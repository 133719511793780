import React from "react"

import { Error } from "@common/EcosuiteComponent"
import EcosuiteModule from "@common/module/EcosuiteModule"

import ProjectList from "./project/ProjectList"
import { ProjectTabs } from "./project/ProjectView"
import RecordView from "./record/RecordView"
import RecordDocumentList from "./record/RecordDocumentList"
import ProjectContactsView from "./project/ProjectContactsView"
import ProjectFinanceView from "./project/ProjectFinanceView"
import I2XView from "@dashboard/data/i2X/I2XView"
import AuditView from "./audit/AuditView"
import ProFormaView from "./pro-forma/ProFormaView"
import RawDataView from "./raw-data/RawDataView"
import "./DataModule.css"
import TariffView from "@dashboard/data/tariffs/TariffView"
import i18n from "src/i18n"

const { t } = i18n
class AssetModule extends EcosuiteModule {
  constructor(props) {
    super(props, "asset")

    // TODO: REFACTOR
    this.state = {
      ...this.state,
      projectView: props.projectViewId ?? this.state.projectView,
    }
  }

  renderView() {
    return <div className="Assets">{super.renderView()}</div>
  }

  renderModuleView() {
    if (this.props.record) {
      return <RecordView {...this.props} />
    }
    switch (this.state.moduleView) {
      case "portfolio-list":
      case "project-list":
        return <ProjectList {...this.props} />
      case "tariffs":
        return <TariffView />
      case "record-list":
        return <RecordView {...this.props} />
      case "record-doc-list":
        return <RecordDocumentList {...this.props} range={this.state.range} />
      case "raw-data-list":
        return <RawDataView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ` + this.state.moduleView }} />
    }
  }

  renderProjectView() {
    if (this.props.record) {
      return <RecordView {...this.props} />
    }
    switch (this.state.projectView) {
      case "portfolio-list":
      case "pro-forma":
        return <ProFormaView {...this.props} />
      case "project-tabs":
        return <ProjectTabs {...this.props} />
      case "project-contacts":
        return this.props.groups.includes("contacts") ? <ProjectContactsView {...this.props} /> : null
      case "project-finance":
        return <ProjectFinanceView {...this.props} />
      case "tariffs":
        return <TariffView />
      case "record-list":
        return <RecordView {...this.props} />
      case "record-doc-list":
        return <RecordDocumentList {...this.props} range={this.state.range} />
      case "project-audit":
        return <AuditView {...this.props} />
      case "i2x":
        return <I2XView project={this.props.project} />
      case "raw-data-list":
        return <RawDataView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ` + this.state.projectView }} />
    }
  }

  getDefaultProjectView() {
    const view = super.getDefaultProjectView()
    return this.getProjectView(view).childViewId
  }
}

export default AssetModule
