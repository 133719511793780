import React from "react"
import moment from "moment-timezone"
import { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import { ResponsiveContainer, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Bar } from "recharts"
import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"
import Utils from "@common/utils/Utils"
import Aggregations from "@common/Aggregations"
import { CostGraphTooltip } from "./CostGraphTooltip"
import i18n from "src/i18n"
import Icon from "@common/display/Icon"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

const { t } = i18n
export default class DemandChargeGraph extends DateRangeGraph {
  getMinimumAggregation() {
    return Aggregations.Hour
  }

  renderContent() {
    if (this.props.consumptionCostDatums) {
      let data = Object.keys(this.props.consumptionCostDatums).map((dateKey) => {
        let date = moment(dateKey)

        return {
          date: date.toDate(),
          time: date.toDate().getTime(),
          name: date.format("lll"),
          demandCharge:
            this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey]
              ? this.props.consumptionCostDatums[dateKey].demandCharge
              : null,
          peakDemandCharge:
            this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey]
              ? this.props.consumptionCostDatums[dateKey].peakDemandCharge
              : null,
        }
      })

      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"

      return (
        <>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
              <XAxis
                dataKey="time"
                domain={this.getDomain(this.props.range, this.props.aggregation)}
                tickFormatter={(time) => {
                  return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
                }}
              />
              <YAxis
                tickFormatter={(amount) => {
                  return Utils.formatCurrency(amount)
                }}
              />
              <Tooltip
                content={
                  <CostGraphTooltip
                    assets={this.props.assets}
                    getDataTitle={getDataTitle}
                    range={this.props.datumsRange}
                    aggregation={this.props.datumsAggregation}
                  />
                }
              />

              {this.props.datumsAggregation !== "month" ? (
                <Line
                  name={getDataTitle("demandCharge")}
                  type={graphType}
                  dataKey="demandCharge"
                  stroke={GRAPH_COLORS[1]}
                  fill={"none"}
                  dot={false}
                  strokeWidth={1}
                  strokeDasharray="4 2"
                />
              ) : null}
              <Bar
                name={getDataTitle("peakDemandCharge")}
                type={graphType}
                dataKey="peakDemandCharge"
                stroke={GRAPH_COLORS[1]}
                fill={GRAPH_COLORS[1]}
                dot={true}
              />

              <Legend iconType="plainline" />
            </ComposedChart>
          </ResponsiveContainer>
          <Icon id="max_demand_help" icon="help" style={{ top: "-29px", left: "-25px", float: "right" }} />
          <UncontrolledTooltip target="max_demand_help">
            {t("econode.messages.max_demand_charge_help")}
          </UncontrolledTooltip>
        </>
      )
    }

    return <Loading />
  }
}

export var getDataTitle = function (name) {
  switch (name) {
    case "demandCharge":
      return `${t("econode.dataTitles.max_demand_charges")}`
    case "peakDemandCharge":
      return `${t("econode.dataTitles.peak_max_demand_charges")}`
    default:
      return name
  }
}
