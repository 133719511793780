import React from "react"
import { Trans, withTranslation } from "react-i18next"
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import Clock from "react-live-clock"
import { Link } from "react-router-dom"
import { Auth } from "aws-amplify"
import EcosuiteComponent from "./common/EcosuiteComponent"
import * as Tracker from "./common/utils/TrackingUtils"
import FeedbackBackdropDialog from "./common/input/button/FeedbackBackdropDialog"
import { sendFeedback } from "./FeedbackService"
import Icon from "@common/display/Icon"
import i18n from "./i18n"
import InviteUser from "./InviteUserButton"
import LanguageSelect from "@common/input/select/LanguageSelect"
import SalesForceMenuItem from "./SalesForceMenuItem"
import About from "./About"
import HeaderLogo from "./HeaderLogo"

class Header extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.loadLatestVersion = this.loadLatestVersion.bind(this)

    this.state.interval = setInterval(this.loadLatestVersion, 3600000) // We check for a new version every hour
    this.state.showFeedback = false
    this.state.aboutPopout = false

    this.aboutPopout = this.aboutPopout.bind(this)
    this.aboutPopoutClosed = this.aboutPopoutClosed.bind(this)
  }

  componentWillUnmount() {
    if (this.state.interval) {
      clearInterval(this.state.interval)
    }
  }

  loadLatestVersion() {
    // We make a direct server request to check the latest version
    fetch("/manifest.json").then((response) => {
      return response.json().then((manifest) => {
        this.setStateIfMounted({ latestVersion: manifest.version })
      })
    })
  }

  render() {
    let outdated = this.state.latestVersion && this.state.latestVersion !== this.props.version
    const { t } = i18n

    return (
      <header className="App-header">
        <HeaderLogo userId={this.props.userId} />
        {/* <Link
          to="/"
          onClick={() => {
            Tracker.emit({ name: "App: AMS" })
          }}
        >
          <img src={logo} className="logo" alt="logo" style={{ marginRight: 20 }} />
        </Link> */}

        <div className="controls" id={"app-header-controls"}>
          {outdated ? (
            <div
              className="app-update"
              title={`${t("messages.new_version_available")}: ${this.props.version + " < " + this.state.latestVersion}`}
            >
              <Button
                onClick={() => {
                  window.location.reload()
                }}
              >
                <Icon icon="update" /> {t("buttons.update")}
              </Button>
            </div>
          ) : null}

          <div className="clock" style={{ marginTop: "15px", maxWidth: "500px", flex: 1 }} />

          <div className="stage" style={{ marginTop: "8px", paddingRight: "6px" }}>
            <LanguageSelect value={i18n.language} onChange={i18n.changeLanguage} />
          </div>
          <div className="stage">{process.env.REACT_APP_STAGE !== "prod" ? process.env.REACT_APP_STAGE : ""}</div>
          <div className="clock" title={this.props.timezone}>
            <Clock format={"lll"} ticking={true} timezone={this.props.timezone} />
          </div>
          <div className="help">
            <a title="User Guide" href="https://docs.ecosuite.io/user-guide/" target="_blank" rel="noreferrer noopener">
              <Icon icon="help" />
            </a>
          </div>
          <div className="admin-menu">
            <AMSMenu
              groups={this.props.groups}
              userType={this.props.userType}
              viewType={this.props.viewType}
              userId={this.props.userId}
            />
          </div>
          <div className="admin-menu">
            <UserMenu {...this.props} toggleAbout={this.aboutPopout} />
          </div>
        </div>
        {this.state.aboutPopout ? <About version={this.props.version} close={this.aboutPopoutClosed} /> : null}
      </header>
    )
  }

  aboutPopout() {
    this.setState({ aboutPopout: true })
    Tracker.emit("Enabled!")
  }

  aboutPopoutClosed() {
    this.setState({ aboutPopout: false })
  }
}

class AMSMenu extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false,
    }
  }

  toggle() {
    this.setStateIfMounted({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  renderContent() {
    const { t } = i18n
    if (this.props.userType.application !== "econode") {
      return (
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          {this.props.groups.includes("user-limited-admin") && <InviteUser />}
          <DropdownToggle caret title="Apps">
            <Icon icon="apps" />
          </DropdownToggle>
          <DropdownMenu end={true}>
            {this.props.groups.indexOf("power-user") >= 0 ? (
              <Link
                to="/power-dashboard"
                target="_blank"
                onClick={() => {
                  Tracker.emit({ name: "App: Power Dashboard" })
                }}
              >
                <DropdownItem>
                  <div style={{ display: "flex" }}>
                    <Trans i18nKey="header.amsMenu.power_dashboard">
                      Power<sup>2</sup>Dashboard
                    </Trans>
                    <Icon icon="open_in_new" />
                  </div>
                </DropdownItem>
              </Link>
            ) : null}

            <SalesForceMenuItem
              onClick={() => {
                Tracker.emit({ name: "App: Sales Force" })
              }}
              userId={this.props.userId}
            />

            {process.env.REACT_APP_ECOGY_JUPYTER_URL && this.props.groups.includes("data-analysis") ? (
              <DropdownItem
                onClick={() => {
                  window.open(process.env.REACT_APP_ECOGY_JUPYTER_URL)
                  Tracker.emit({ name: "App: jupyter" })
                }}
              >
                Jupyter <Icon icon="open_in_new" />
              </DropdownItem>
            ) : null}

            <DropdownItem divider />

            {this.props.groups.includes("user-admin") ? (
              <Link
                to="/admin/users"
                target="_blank"
                onClick={() => {
                  Tracker.emit({ name: "App: User Admin" })
                }}
              >
                <DropdownItem>
                  {t("header.amsMenu.manage_users")} <Icon icon="open_in_new" />
                </DropdownItem>
              </Link>
            ) : null}

            {this.props.groups.includes("contacts") ? (
              <Link
                to="/admin/contacts"
                target="_blank"
                onClick={() => {
                  Tracker.emit({ name: "App: Contacts" })
                }}
              >
                <DropdownItem>
                  {t("header.amsMenu.contacts")} <Icon icon="open_in_new" />
                </DropdownItem>
              </Link>
            ) : null}

            {this.props.groups.includes("solarnetwork") || this.props.groups.includes("data-write") ? (
              <Link
                to="/admin/settings"
                target="_blank"
                onClick={() => {
                  Tracker.emit({ name: "App: SolarNetwork" })
                }}
              >
                <DropdownItem>
                  {t("header.amsMenu.settings")} <Icon icon="open_in_new" />
                </DropdownItem>
              </Link>
            ) : null}

            <DropdownItem divider />

            {this.props.groups.includes("user-admin") ? (
              <Link
                to="/admin/organization-settings"
                target="_blank"
                onClick={() => {
                  Tracker.emit({ name: "App: Organization Settings" })
                }}
              >
                <DropdownItem>
                  Org preferences <Icon icon="open_in_new" />
                </DropdownItem>
              </Link>
            ) : null}

            {this.props.groups.includes("user-admin") ? (
              <Link
                to="/admin/onboarding"
                target="_blank"
                onClick={() => {
                  Tracker.emit({ name: "App: Onboarding" })
                }}
              >
                <DropdownItem>Onboarding</DropdownItem>
              </Link>
            ) : null}

            <DropdownItem divider />
          </DropdownMenu>
        </ButtonDropdown>
      )
    }

    return null
  }
}

class UserMenu extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.toggleViewType = this.toggleViewType.bind(this)

    this.state = {
      dropdownOpen: false,
    }
  }

  toggle() {
    this.setStateIfMounted({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  signOut() {
    Auth.signOut().then(() => {
      window.location.href = "/"
    })
  }

  toggleViewType() {
    if (this.props.viewType === "econode") {
      this.props.actions.selectViewType()
    } else {
      this.props.actions.selectViewType("econode")
    }
  }

  renderContent() {
    const { t } = i18n

    return (
      <>
        <FeedbackBackdropDialog
          title={`${t("labels.send_feedback")}`}
          isOpen={this.state.showFeedback}
          body={<p>{t("messages.want_feedback")}</p>}
          inputPlaceholder={`${t("notes.valuable_feedback")}`}
          inputValidator={(value) => {
            return value && value !== ""
          }}
          onValidationErrorMessage={`${t("errors.feedback_required")}`}
          onConfirm={async (bool, input) =>
            await sendFeedback(this.props.version, bool ? undefined : this.props.username, input)
          }
          onComplete={() =>
            this.setState({
              showFeedback: false,
            })
          }
          onCancel={() => this.setState({ showFeedback: false })}
        />
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret title={this.props.username}>
            <Icon icon="account_circle" /> {this.props.userFirstName ? this.props.userFirstName : this.props.username}
          </DropdownToggle>
          <DropdownMenu end={true}>
            <Link
              to="/profile"
              onClick={() => {
                Tracker.emit({ name: "User: Profile" })
              }}
            >
              <DropdownItem>{t("header.userMenu.profile")}</DropdownItem>
            </Link>
            <Link
              to="/profile/notifications"
              onClick={() => {
                Tracker.emit({ name: "User: Notifications" })
              }}
            >
              <DropdownItem>{t("header.userMenu.notifications")}</DropdownItem>
            </Link>
            {this.props.groups.includes("reseller") && this.props.userType.application !== "econode" ? (
              <DropdownItem onClick={this.toggleViewType}>
                {this.props.viewType === "econode"
                  ? `${t("header.userMenu.switch_to_ams")}`
                  : `${t("header.userMenu.switch_to_econode")}`}
              </DropdownItem>
            ) : null}
            <DropdownItem onClick={() => this.setState({ showFeedback: true })}>
              {t("header.userMenu.feedback")}
            </DropdownItem>
            <DropdownItem>
              <div
                onClick={() => {
                  this.props.toggleAbout()
                }}
              >
                About
              </div>
            </DropdownItem>
            <DropdownItem onClick={this.signOut}>{t("header.userMenu.logout")}</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </>
    )
  }
}

export default withTranslation()(Header)
