import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import React, { useState } from "react"
import { listTariffs } from "@dashboard/data/tariffs/TariffService"
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead"
import i18n from "src/i18n"

const { t } = i18n
interface TariffAutoCompleteProps {
  selected?: string
  onSelect: (id: string) => void
}

/**
 * The tariff auto complete.
 * @param props - The props.
 * @constructor
 */
export const TariffAutoComplete = (props: TariffAutoCompleteProps) => {
  const { selected, onSelect } = props

  const [tariffs, setTariffs] = useState<Tariff[] | undefined>(undefined)
  const [text, setText] = useState("")
  const [selectedTariff, setSelectedTariff] = useState<Tariff | undefined>(undefined)

  useCurrentEffect((isCurrent) => {
    init(isCurrent)
  }, [])

  async function init(isCurrent: () => boolean) {
    await Promise.all([listTariffs()]).then(([tariffs]) => {
      if (isCurrent()) {
        const tarrif = getTariffById(tariffs, selected)
        setTariffs(tariffs)
        setSelectedTariff(tarrif)
        setText(identifyTariff(tarrif))
      }
    })
  }

  /**
   * Build the media options.
   */
  const buildOptions = () => {
    return tariffs ? tariffs.map((tariff) => identifyTariff(tariff)) : []
  }

  const identifyTariff = (tariff: Tariff) => {
    return tariff?.name || tariff?.rate || tariff?.id || ""
  }

  const getTariffById = (tariffs: Array<Tariff>, id: string | undefined) => {
    const tariff = tariffs.find((t) => t.id === id)
    return tariff
  }

  const getTariffId = (tariffs: Array<Tariff> | undefined, name: string) => {
    if (!name || !tariffs) return ""
    const tariff = tariffs.find((t) => {
      if (t.name === name)return true
      if (t.rate === name)return true
      if (t.id === name)return true
      return false
    })
    setSelectedTariff(tariff)
    setText(tariff?.name || tariff?.rate || tariff?.id || name)
    return tariff?.id || name
  }
  
  return (
    <>
    <Typeahead
      id="tariff"
      options={buildOptions()}
      isLoading={tariffs === undefined}
      inputProps={{ required: true }}
      placeholder={`${t("data.dialogs.enter_tariff")}`}
      onChange={(tariff) => onSelect.call(this, getTariffId(tariffs, tariff[0]))}
      selected={text ? [text] : []}
      renderMenu={(results, menuProps) => {
        return (
          <Menu {...menuProps}>
            {results.map((r, i) => (
              <MenuItem option={r} position={i} key={r}>
                {r}
              </MenuItem>
            ))}
          </Menu>
        )
      }}
    />
      {selectedTariff?.id}
    </>
  )
}
