export const defaultWidgets = {
  applications: [
    {
      name: "ams",
      widgets: [
        {
          name: "Last Months Generation",
          location: 0,
          enabled: true,
        },
        {
          name: "Avoided Emissions",
          location: 1,
          enabled: true,
        },
        {
          name: "Instantaneous KW",
          location: 2,
          enabled: true,
        },
        {
          name: "Energy Consumed By",
          location: 3,
          enabled: true,
        },
        {
          name: "Self Consumption",
          location: 4,
          enabled: true,
        },
        {
          name: "Generation Vs Consumption",
          location: 5,
          enabled: true,
        },
      ],
    },
    {
      name: "econode",
      widgets: [
        {
          name: "Actual This Period",
          location: 0,
          enabled: true,
        },
        {
          name: "Actual Last Period",
          location: 1,
          enabled: true,
        },
        {
          name: "Compared to Last Period",
          location: 2,
          enabled: true,
        },
        {
          name: "Forecast This Period",
          location: 3,
          enabled: true,
        },
        {
          name: "Compared to Forecast",
          location: 4,
          enabled: true,
        },
        {
          name: "Energy Consumed",
          location: 5,
          enabled: true,
        },
        {
          name: "Emissions",
          location: 6,
          enabled: true,
        },
        {
          name: "Damos Widget",
          location: 7,
          enabled: false,
        },
      ],
    },
  ],
}

export default defaultWidgets
