import React from "react"
import { Alert } from "reactstrap"
import i18n from "src/i18n"

import ProFormaUtils from "./ProFormaUtils"

const { t } = i18n

export default {
  renderForecastGenerationInfo(project, proForma) {
    if (ProFormaUtils.isVersion(proForma)) {
      if (proForma.versionProject) {
        if (proForma.versionProject?.dcSize) {
          return (
            <Alert color="info">
              <b>{t("economics.labels.Project/System Forecast")}:</b>
              {` ${t("economics.messages.pro_forma_version_same_time_forecast")}`}
            </Alert>
          )
        } else {
          return (
            <Alert color="warning">
              <b>{t("economics.labels.Pro Forma Forecast")}:</b>
              {` ${t("economics.messages.pro_forma_version_no_generation_system")}`}
            </Alert>
          )
        }
      } else {
        return (
          <Alert color="warning">
            <b>{t("economics.labels.Pro Forma Forecast")}:</b>
            {` ${t("economics.messages.pro_forma_version_no_matching_configuration")}`}
          </Alert>
        )
      }
    } else {
      if (project?.dcSize) {
        return (
          <Alert color="primary">
            <b>{t("economics.labels.Project/System Forecast")}:</b>
            {t("economics.messages.system_size_production_degradation_configured")}
          </Alert>
        )
        //dont show warning if this is a consumption project
      } else if (!project?.consumptionStartDate) {
        return (
          <Alert color="warning">
            <b>{t("economics.labels.Pro Forma Forecast")}:</b>
            {t("economics.messages.project_system_no_generation_system_configured")}
          </Alert>
        )
      }
    }
  },
}
