import React from "react"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import Utils from "@common/utils/Utils"
import ProjectUtils from "@common/utils/ProjectUtils"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import AssetStatus from "./AssetStatus"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
class AssetSummary extends AssetStatus {
  renderContent() {
    const path = ProjectUtils.getPath(this.props.project, this.props.site, this.props.system).replace(/\//g, "_")
    let expectedGenerationContent = null
    let isIrradianceComplete = EnergyUtils.isIrradianceCompleteForRange(
      this.props.project,
      this.props.site,
      this.props.system,
      this.props.range,
    )
    if (!isIrradianceComplete && this.props.expectedGeneration && this.props.expectedGeneration.expectedGeneration) {
      expectedGenerationContent = (
        <span className="warning-info">
          <Icon icon="warning" id={`incomplete-irr-${path}`} />
          <UncontrolledTooltip placement="right" target={`incomplete-irr-${path}`}>
            {t("energy.tooltips.expected_gen", {
              date: EnergyUtils.formatWattHoursToPreferrence(this.props.expectedGeneration.expectedGeneration),
            })}
          </UncontrolledTooltip>
        </span>
      )
    }

    return (
      <div className={this.props.className}>
        {this.getStatusMessage()}

        {this.props.showGeneration ? (
          <React.Fragment>
            {this.props.firstYearForecast && this.props.nextYearForecast ? (
              <React.Fragment>
                <div className="generation float-start">
                  <span id={`first-year-gen-${path}`}>
                    <label>{`${t("energy.labels.year_one_forecast_gen")}: `}</label>{" "}
                    {EnergyUtils.formatWattHoursToPreferrence(this.props.firstYearForecast.predictedGeneration)}
                  </span>
                  <UncontrolledTooltip placement="bottom" target={`first-year-gen-${path}`}>
                    {t("energy.tooltips.forecast_gen_first_12Months")}
                  </UncontrolledTooltip>
                </div>
                <div className="generation float-start">
                  <span id={`next-year-gen-${path}`}>
                    <label>{`${t("energy.labels.current_yearly")}: `}</label>{" "}
                    {EnergyUtils.formatWattHoursToPreferrence(this.props.nextYearForecast.predictedGeneration)}
                  </span>
                  <UncontrolledTooltip placement="bottom" target={`next-year-gen-${path}`}>
                    {t("energy.tooltips.forecast_gen_12Months")}
                  </UncontrolledTooltip>
                </div>
              </React.Fragment>
            ) : null}
            <div className="asset-info generation">
              <span id={`asset-gen-${path}`}>
                <label>{`${t("labels.generation")}: `}</label>{" "}
                {this.isContentValid(this.props.readings)
                  ? EnergyUtils.formatWattHoursToPreferrence(this.props.readings.generation)
                  : null}{" "}
                {this.isContentValid(this.props.readings) &&
                this.isContentValid(this.props.expectedGeneration) &&
                this.isContentValid(this.props.expectedGeneration.expectedGeneration) ? (
                  <React.Fragment>
                    {" of "}
                    {EnergyUtils.formatWattHoursToPreferrence(this.props.expectedGeneration.expectedGeneration)}
                    {", "}(
                    {EnergyUtils.getPercent(
                      this.props.readings.generation,
                      this.props.expectedGeneration.expectedGeneration,
                    ) + "%"}
                    )
                  </React.Fragment>
                ) : null}
              </span>
              {expectedGenerationContent}
              <UncontrolledTooltip placement="bottom" target={`asset-gen-${path}`}>
                {t("energy.tooltips.actual_recorded_gen")}
              </UncontrolledTooltip>
            </div>
            <div className="asset-info size">
              <label>{`${t("table_headings.dc_size")}: `}</label>{" "}
              {this.props.asset && this.props.asset.dcSize
                ? EnergyUtils.formatKiloWattsToPreferrence(this.props.asset.dcSize)
                : " - "}
              {this.props.dcSizeWarning && (
                <>
                  <Icon id={"dc-warning"} icon="error" className="size-warning" />
                  <UncontrolledTooltip placement="top" target={`dc-warning`}>
                    {t("energy.tooltips.combined_dc")}
                  </UncontrolledTooltip>
                </>
              )}
            </div>
            <div className="asset-info size">
              <label>{`${t("table_headings.ac_size")}: `}</label>{" "}
              {this.props.asset && this.props.asset.acSize
                ? EnergyUtils.formatKiloWattsToPreferrence(this.props.asset.acSize)
                : " - "}
              {this.props.acSizeWarning && (
                <>
                  <Icon id={"ac-warning"} icon="error" className="size-warning" />
                  <UncontrolledTooltip placement="top" target={`ac-warning`}>
                    {t("energy.tooltips.combined_ac")}
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </React.Fragment>
        ) : null}

        {this.props.showConsumption ? (
          <React.Fragment>
            <div className="asset-info consumption">
              <label>{`${t("labels.consumption")}: `}</label>{" "}
              {this.isContentValid(this.props.readings)
                ? EnergyUtils.formatWattHoursToPreferrence(this.props.readings.consumption)
                : null}{" "}
              {this.isContentValid(this.props.readings) &&
              this.isContentValid(this.props.predictedConsumption) &&
              this.isContentValid(this.props.predictedConsumption.predictedConsumption) ? (
                <React.Fragment>
                  {" of "}
                  {EnergyUtils.formatWattHoursToPreferrence(this.props.predictedConsumption.predictedConsumption)}
                  {", "}(
                  {EnergyUtils.getPercent(
                    this.props.readings.consumption,
                    this.props.predictedConsumption.predictedConsumption,
                  ) + "%"}
                  )
                </React.Fragment>
              ) : null}
            </div>
            <div className="asset-info consumption">
              <label>{`${t("labels.cost")}: `}</label>{" "}
              {this.isContentValid(this.props.consumptionCost)
                ? Utils.formatCurrency(this.props.consumptionCost.consumptionCost.totalCost)
                : " - "}
            </div>
          </React.Fragment>
        ) : null}

        {this.props.showStorage ? (
          <React.Fragment>
            <div className="asset-info consumption">
              <label>{`${t("labels.storage")}: `}</label>{" "}
              {this.isContentValid(this.props.readings)
                ? EnergyUtils.formatWattHoursToPreferrence(this.props.readings.storage)
                : null}{" "}
            </div>
            <div className="asset-info consumption">&nbsp;</div>
          </React.Fragment>
        ) : null}

        {this.props.asset && this.props.asset.address ? (
          <div className="address">
            <label>{`${t("labels.address")}: `}</label> {this.props.asset.address}
          </div>
        ) : null}

        {this.props.asset && this.props.asset.accessNotes ? (
          <div className="accessNotes">
            <label>{`${t("labels.access_notes")}: `}</label> {this.props.asset.accessNotes}
          </div>
        ) : null}

        {this.props.asset && this.props.asset.plantId ? (
          <div className="asset-info plant">
            <label>{`${t("labels.plant_id")}: `}</label> {this.props.asset.plantId}
          </div>
        ) : null}

        {this.props.asset && this.props.asset.einNumber ? (
          <div className="asset-info plant">
            <label>{`${t("labels.ein_number")}: `}</label> {this.props.asset.einNumber}
          </div>
        ) : null}

        {this.props.asset && this.props.asset.links && this.props.asset.links.length > 0 ? (
          <div className="asset-info links">
            <label>{`${t("headings.links")}: `}</label>
            <ul>
              {this.props.asset.links.map((link, idx) => {
                return (
                  <li key={idx}>
                    <a href={link.url} target="_blank" rel="noreferrer noopener">
                      {link.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        ) : null}
      </div>
    )
  }
}

export default AssetSummary
