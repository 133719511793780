import React, { Component } from "react"
import moment from "moment"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import i18n from "src/i18n"

const { t } = i18n

class AssetInfo extends Component {
  render() {
    return this.props.asset ? this.getInfo(this.props.asset) : null
  }

  getInfo(asset) {
    switch (asset.type) {
      case "generation":
        return (
          <React.Fragment>
            <h4>{t("headings.info")}</h4>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>{t("table_headings.dc_size")}:</td>
                    <td className="numerical">
                      {this.props.asset.dcSize
                        ? EnergyUtils.formatKiloWattsToPreferrence(this.props.asset.dcSize)
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("table_headings.ac_size")}:</td>
                    <td className="numerical">
                      {this.props.asset.acSize
                        ? EnergyUtils.formatKiloWattsToPreferrence(this.props.asset.acSize)
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>{t("headings.forecast_generation")}</h4>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      {moment(Date.now()).startOf("year").format("MMM YYYY")} ~{" "}
                      {moment(Date.now()).endOf("year").format("MMM YYYY")}:
                    </td>
                    <td className="numerical">
                      {this.props.predictedGeneration
                        ? EnergyUtils.formatWattHoursToPreferrence(this.props.predictedGeneration.predictedGeneration)
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("table_headings.normalised_forecast")}:</td>
                    <td className="numerical">
                      {this.props.predictedGeneration && this.props.asset.dcSize
                        ? EnergyUtils.formatWattHoursToPreferrence(
                            this.props.predictedGeneration.predictedGeneration / this.props.asset.dcSize,
                          ) + "/kWp"
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )
      case "consumption":
        return (
          <React.Fragment>
            <h4>{t("headings.info")}</h4>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>{t("table_headings.expected_peak")}:</td>
                    <td className="numerical">
                      {this.props.asset.peakPower
                        ? EnergyUtils.formatKiloWattsToPreferrence(this.props.asset.peakPower)
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )
      case "storage":
        return null
      default:
        // The default is for the Project/Site that don't have a type
        return (
          <React.Fragment>
            <h4>{t("headings.info")}</h4>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>{t("table_headings.dc_size")}:</td>
                    <td className="numerical">
                      {this.props.asset.dcSize
                        ? EnergyUtils.formatKiloWattsToPreferrence(this.props.asset.dcSize)
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("table_headings.ac_size")}:</td>
                    <td className="numerical">
                      {this.props.asset.acSize
                        ? EnergyUtils.formatKiloWattsToPreferrence(this.props.asset.acSize)
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("table_headings.expected_peak")}:</td>
                    <td className="numerical">
                      {this.props.asset.peakPower
                        ? EnergyUtils.formatKiloWattsToPreferrence(this.props.asset.peakPower)
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {this.props.predictedGeneration &&
              this.props.predictedGeneration.predictedGeneration !== 0 &&
              this.props.asset.dcSize !== 0 && (
                <>
                  <h4>{t("headings.forecast_generation")}</h4>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {moment(Date.now()).startOf("year").format("MMM YYYY")} ~{" "}
                            {moment(Date.now()).endOf("year").format("MMM YYYY")}:
                          </td>
                          <td className="numerical">
                            {this.props.predictedGeneration
                              ? EnergyUtils.formatWattHoursToPreferrence(
                                  this.props.predictedGeneration.predictedGeneration,
                                )
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("table_headings.normalised_forecast")}:</td>
                          <td className="numerical">
                            {this.props.predictedGeneration && this.props.asset.dcSize
                              ? EnergyUtils.formatWattHoursToPreferrence(
                                  this.props.predictedGeneration.predictedGeneration / this.props.asset.dcSize,
                                ) + "/kWp"
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
          </React.Fragment>
        )
    }
  }
}

export default AssetInfo
