import React from "react"
import moment from "moment"
import { create, all } from "mathjs"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import DeviceUtils from "@common/utils/DeviceUtils"
import i18n from "src/i18n"

const math = create(all)
const { t } = i18n

export default class LiveSourceTable extends EcosuiteComponent {
  formatSourceReading(nodeStatus, reading) {
    if (EnergyUtils.isIrradianceDatum(nodeStatus)) {
      return reading || reading === 0 ? EnergyUtils.formatNumberAsKilo(reading) + " kWh/m2" : undefined
    } else if (reading || reading === 0) {
      return EnergyUtils.formatWattHoursAsKiloWattHours(reading)
    }
  }

  isShowSource(nodeStatus) {
    switch (EnergyUtils.getDeviceType(nodeStatus)) {
      case "GEN":
      case "INV":
      case "PYR":
        return this.props.showGeneration
      case "CON":
      case "LOAD":
      case "MET":
      case "EXP":
        return this.props.showConsumption
      default:
        return true
    }
  }

  renderContent() {
    let days = this.props.readings ? moment(this.props.range.end).diff(moment(this.props.range.start), "days") : 0
    if (!days) days = 1 // cater for zooming into hour
    let otherLoad = 0
    let showOtherLoad = false

    return this.props.nodesStatus ? (
      <div>
        <table>
          <tbody>
            <tr>
              <th>{t("table_headings.node")}</th>
              <th>{t("table_headings.source_id")}</th>
              <th>{t("table_headings.latest_reading_date")}</th>
              <th>{t("table_headings.last_24_reading")}</th>
              {this.props.showGeneration ? <th>{`${t("table_headings.dc_size")} (kW)`}</th> : null}
              {this.props.showGeneration ? <th>{`${t("table_headings.ac_size")} (kW)`}</th> : null}
              <th>{t("table_headings.message")}</th>
            </tr>
            {Object.values(this.props.nodesStatus).map((nodeStatus) => {
              const dcSize = DeviceUtils.getDeviceDCSize(this.props.system, nodeStatus.sourceId)
              const acSize = DeviceUtils.getDeviceACSize(this.props.system, nodeStatus.sourceId)

              if (this.isShowSource(nodeStatus)) {
                return (
                  <React.Fragment key={nodeStatus.nodeId + nodeStatus.sourceId}>
                    <tr className={nodeStatus.status}>
                      <td>{nodeStatus.nodeId}</td>
                      <td>{nodeStatus.sourceId}</td>
                      <td>{moment(nodeStatus.latestDatumDate).format("lll")}</td>
                      <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.reading)}</td>
                      {this.props.showGeneration ? (
                        <td className={"numerical"}>
                          {dcSize ? EnergyUtils.formatKiloWattsToPreferrence(dcSize) : ""}
                        </td>
                      ) : null}
                      {this.props.showGeneration ? (
                        <td className={"numerical"}>
                          {acSize ? EnergyUtils.formatKiloWattsToPreferrence(acSize) : ""}
                        </td>
                      ) : null}
                      <td>
                        {nodeStatus.causes
                          ? nodeStatus.causes.map((cause) => {
                              return <div key={cause.message}>{cause.message}</div>
                            })
                          : null}
                      </td>
                    </tr>
                  </React.Fragment>
                )
              }
              return null
            })}

            {showOtherLoad ? this.getOtherLoadRow(otherLoad, days) : null}
          </tbody>
        </table>
      </div>
    ) : (
      <Loading />
    )
  }

  getSytemSourcePrefix() {
    return "/" + this.props.project.code + "/" + this.props.site.code + "/" + this.props.system.code
  }

  getOtherLoadRow(otherLoad, days) {
    if (this.props.system && this.props.showConsumption) {
      // We a virtual LOAD/OTHER source ID for the load that isn't being tracked by a SolarNetwork node
      let otherLoadSourceId = this.getSytemSourcePrefix() + "/LOAD/OTHER"
      let size = this.getSourceDCSize(otherLoadSourceId)
      let acSize = this.getSourceACSize(otherLoadSourceId)
      let peakPower = this.getSourcePeakPower(otherLoadSourceId)
      let expected = this.getExpected({ sourceId: otherLoadSourceId })

      return (
        <tr key={"project-row-" + otherLoadSourceId}>
          <td />
          <td>{otherLoadSourceId}</td>
          <td />
          <td />
          <td className={"numerical"}>{size ? EnergyUtils.formatKiloWattsToPreferrence(size) : ""}</td>
          <td className={"numerical"}>{acSize ? EnergyUtils.formatKiloWattsToPreferrence(acSize) : ""}</td>
          <td className={"numerical"}>{peakPower ? EnergyUtils.formatKiloWattsToPreferrence(peakPower) : ""}</td>

          <td className="numerical ranged">{EnergyUtils.formatWattHoursAsKiloWattHours(otherLoad)}</td>
          <td className="numerical ranged">{expected ? EnergyUtils.formatWattHoursAsKiloWattHours(expected) : null}</td>
          <td className="numerical ranged">
            {expected && otherLoad ? math.round((otherLoad / expected) * 100) + "%" : null}
          </td>
          <td className="numerical ranged">{this.getNormalisedGeneration(otherLoad, size, days)}</td>
        </tr>
      )
    }
  }
}
