import React from "react"
import moment from "moment"
import { Container, Row, Col } from "reactstrap"

import ProjectUtils from "@common/utils/ProjectUtils"
import { Loading, Error } from "@common/EcosuiteComponent"

import EconodeUtils from "src/econode/EconodeUtils"

import EnergyView from "./EconodeEnergyView"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import i18n from "src/i18n"

const { t } = i18n
const COLUMNS = 4

export default class EconodeMonitorView extends EnergyView {
  renderMainView() {
    if (this.props.projects) {
      return <div className="econode-monitors">{this.props.project ? this.renderProject() : this.renderProjects()}</div>
    } else {
      return <Loading />
    }
  }

  renderProject() {
    if (this.isContentError(this.props.projectStatus)) {
      return <Error error={this.props.projectStatus.getError()} />
    }
    if (this.isContentValid(this.props.projectStatus)) {
      let econodes = EconodeUtils.getEconodesForProject(this.props.project)
      let rows = []

      for (var i = 0; i < econodes.length; i += COLUMNS) {
        let row = econodes.slice(i, i + COLUMNS)

        rows.push(
          row.map((econode) => {
            let siteId = ProjectUtils.getSiteCode(econode.code)
            let systemId = ProjectUtils.getSystemCode(econode.code)
            let status =
              this.props.projectStatus.sites &&
              this.props.projectStatus.sites[siteId] &&
              this.props.projectStatus.sites[siteId].systems[systemId]
                ? this.props.projectStatus.sites[siteId].systems[systemId]
                : null

            return {
              econode: econode,
              status: status,
            }
          }),
        )
      }

      return (
        <Container className="project-monitor" fluid={true}>
          {rows.map((row, index) => this.renderRow(row, index))}
        </Container>
      )
    } else {
      return <Loading />
    }
  }

  renderProjects() {
    if (this.isContentError(this.props.projectsStatus)) {
      return <Error error={this.props.projectsStatus.getError()} />
    }
    if (this.isContentValid(this.props.projectsStatus)) {
      let econodes = EconodeUtils.getEconodesForProjects(this.props.projects)
      let rows = []

      for (var i = 0; i < econodes.length; i += COLUMNS) {
        let row = econodes.slice(i, i + COLUMNS)

        rows.push(
          row.map((econode) => {
            let projectId = ProjectUtils.getProjectCode(econode.code)
            let siteId = ProjectUtils.getSiteCode(econode.code)
            let systemId = ProjectUtils.getSystemCode(econode.code)
            let projectStatus = this.props.projectsStatus.projects.find(
              (projectStatus) => projectStatus.code === projectId,
            )
            let status =
              projectStatus &&
              projectStatus.sites &&
              projectStatus.sites[siteId] &&
              projectStatus.sites[siteId].systems[systemId]
                ? projectStatus.sites[siteId].systems[systemId]
                : null

            return {
              econode: econode,
              status: status,
            }
          }),
        )
      }
      return (
        <Container className="projects-monitor" fluid={true}>
          {rows.map((row, index) => this.renderRow(row, index))}
        </Container>
      )
    } else {
      return <Loading />
    }
  }

  renderRow(row, index) {
    return (
      <Row key={index}>
        {row.map((econodeStatus) => {
          return (
            <Col className="econode-monitor-container" key={econodeStatus.econode.code} md={`${12 / COLUMNS}`}>
              {this.renderEconode(econodeStatus.econode, econodeStatus.status)}
            </Col>
          )
        })}
      </Row>
    )
  }

  renderEconode(econode, status) {
    if (status) {
      let consumptionError = status.causes && status.causes.find((cause) => cause.type === "consumption")
      let powerFactorError = status.causes && status.causes.find((cause) => cause.type === "powerFactor")
      let voltageError = status.causes && status.causes.find((cause) => cause.type === "voltage")
      return (
        <div
          key={econode.code}
          className={`econode-monitor ${(status.causes && status.status !== "ok") || status.status === "ok" ? status.status : ""}`}
        >
          <h4>{econode.name}</h4>
          <Container fluid={true}>
            <Row
              className={
                status.causes &&
                status.causes.find((cause) => cause.type === "connectivity" || cause.type === "reading")
                  ? "cause"
                  : null
              }
            >
              <Col>
                <label>{t("econode.dataTitles.Last Reading")}</label>
              </Col>
              <Col>{status.latestReadingDate ? moment(status.latestReadingDate).format("lll") : "-"}</Col>
            </Row>
            <Row className={consumptionError ? "cause" : null}>
              <Col md="6">
                <label>{`${t("labels.actual")} kWh`}</label>
              </Col>
              <Col md="6">{EnergyUtils.formatWattHoursToPreferrence(status.consumption)}</Col>
              {this.renderErrorMessage(consumptionError)}
            </Row>

            <Row>
              <Col>
                <label>{`${t("labels.forecast")} kWh`}</label>
              </Col>
              <Col>{EnergyUtils.formatWattHoursToPreferrence(status.predictedConsumption)}</Col>
            </Row>
            {status.powerFactor || status.powerFactor === 0 ? (
              <Row className={powerFactorError ? "cause" : null}>
                <Col md="6">
                  <label>{t("econode.dataTitles.Power Factor")}</label>
                </Col>
                <Col md="6">{status.powerFactor}</Col>
                {this.renderErrorMessage(powerFactorError)}
              </Row>
            ) : null}
            {status.current || status.current === 0 ? (
              <Row>
                <Col>
                  <label>{t("labels.current")}</label>
                </Col>
                <Col>{status.current}</Col>
              </Row>
            ) : null}
            {status.voltage || status.voltage === 0 ? (
              <Row className={voltageError ? "cause" : null}>
                <Col md="6">
                  <label>{t("econode.dataTitles.Voltage")}</label>
                </Col>
                <Col md="6">{status.voltage}</Col>
                {this.renderErrorMessage(voltageError)}
              </Row>
            ) : null}
          </Container>
          {this.renderPhases(econode, status)}
        </div>
      )
    }
  }

  renderErrorMessage(error) {
    return error ? (
      <Col md="12" className="cause-message">
        {error.message}
      </Col>
    ) : null
  }

  renderPhases(econode, status) {
    if (status.current_a || status.voltage_a) {
      let voltageAError = status.causes && status.causes.find((cause) => cause.type === "voltage_a")
      let voltageBError = status.causes && status.causes.find((cause) => cause.type === "voltage_b")
      let voltageCError = status.causes && status.causes.find((cause) => cause.type === "voltage_c")
      return (
        <Container>
          <h4>{t("econode.labels.Phased")}</h4>
          <Row>
            <Col md="3"></Col>
            <Col md="3">{t("econode.labels.Phase A")}</Col>
            <Col md="3">{t("econode.labels.Phase B")}</Col>
            <Col md="3">{t("econode.labels.Phase C")}</Col>
          </Row>
          {status.current_a ? (
            <Row>
              <Col md="3">{t("labels.current")}</Col>
              <Col md="3">{status.current_a}</Col>
              <Col md="3">{status.current_b}</Col>
              <Col md="3">{status.current_c}</Col>
            </Row>
          ) : null}
          {status.voltage_a ? (
            <Row>
              <Col md="3">{t("econode.dataTitles.Voltage")}</Col>
              <Col md="3" className={voltageAError ? "cause" : null}>
                {status.voltage_a}
              </Col>
              <Col md="3" className={voltageBError ? "cause" : null}>
                {status.voltage_b}
              </Col>
              <Col md="3" className={voltageCError ? "cause" : null}>
                {status.voltage_c}
              </Col>
              {this.renderErrorMessage(voltageAError)}
              {this.renderErrorMessage(voltageBError)}
              {this.renderErrorMessage(voltageCError)}
            </Row>
          ) : null}
        </Container>
      )
    }
  }
}
