import EnergyUtils from "@dashboard/energy/EnergyUtils"
import React from "react"
import moment from "moment"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import FinanceUtils from "../../../../FinanceUtils"
import Categories from "../Categories"
import CategoryUtils from "../CategoryUtils"

import ReportSection from "../ReportSection"
import i18n from "src/i18n"
import Utils from "@common/utils/Utils"
import ActualDetailsTooltip from "./ActualDetailsTooltip"
import { isEmpty } from "lodash"

const { t } = i18n

class CashFlowDifferences extends ReportSection {
  /**
   * Merges the categories found in the different sections of the report into a single category hierarchy.
   */
  getCategories(aggregatedReport) {
    return Categories.mergeReportCategories({}, aggregatedReport)
  }

  renderCategories() {
    const categories = this.getCategories(this.props.report)
    return (
      <React.Fragment>
        {this.renderCategory(categories["Fixed Asset"])}
        {this.renderCategory(categories["Income"])}
        {this.renderCategory(categories["Expense"])}
        {this.renderNetIncome()}
        {this.renderUnleveredTotals()}
        {this.renderCumulativeTotal(this.props.report, "Cumulative Unlevered CF", "unleveredTotals")}
        {this.renderUnleveredIrr()}

        {this.renderCategory(categories["Liability"])}
        {this.renderLeveredTotals()}
        {this.renderLeveredIrr()}

        {this.renderCategory(categories["Equity"])}
        {this.renderCategory(categories["Unassigned"])}

        {this.renderRetainedEarnings()}
        {this.renderTotalValue()}
      </React.Fragment>
    )
  }

  renderCategory(category) {
    if (!category) {
      return null
    } else {
      const lifetimeTotals = this.props.reportTotals.lifetimeTotals
      const toDateTotals = this.props.reportTotals.toDateTotals
      return (
        <React.Fragment>
          <tr className="category">
            <th style={{ textAlign: "left" }} title={category.name}>
              {category.name === "Income" ? "Revenue" : category.name}
            </th>
            {this.props.report.dates.map((date, idx) => {
              const forecast = this.getCategoryTotal(this.props.report, "forecast", category.name, idx)
              const expected = this.getCategoryTotal(this.props.report, "expected", category.name, idx)
              const actual = this.getCategoryTotal(this.props.report, "actual", category.name, idx)
              const latestBestEstimate = this.getCategoryTotal(
                this.props.report,
                "latestBestEstimate",
                category.name,
                idx,
              )
              const difference = actual - forecast
              if (idx < this.props.futureIdx) {
                return (
                  <React.Fragment key={idx}>
                    <td
                      className={`${this.getCellClass(idx)} cash-flow-forecast total ${this.getColumnClass(
                        "forecast",
                      )}`}
                    >
                      {FinanceUtils.formatCurrency(forecast)}
                    </td>
                    <td
                      className={`${this.getCellClass(idx)} cash-flow-expected total ${this.getColumnClass(
                        "expected",
                      )}`}
                    >
                      {FinanceUtils.formatCurrency(expected)}
                    </td>
                    <td className={`${this.getCellClass(idx)} cash-flow-actual total ${this.getColumnClass("actual")}`}>
                      {FinanceUtils.formatCurrency(actual)}
                    </td>
                    <td
                      className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate total ${this.getColumnClass(
                        "latestBestEstimate",
                      )}`}
                    >
                      {FinanceUtils.formatCurrency(latestBestEstimate)}
                    </td>
                    <td
                      className={`${this.getCellClass(idx)} cash-flow-difference total ${this.getColumnClass(
                        "difference",
                      )}`}
                    >
                      {FinanceUtils.formatCurrency(difference)}
                    </td>
                    {this.renderSpacer()}
                  </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={idx}>
                    <td
                      className={`${this.getCellClass(idx)} cash-flow-forecast total ${this.getColumnClass(
                        "forecast",
                      )}`}
                    >
                      {FinanceUtils.formatCurrency(forecast)}
                    </td>
                    <td
                      className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate total ${this.getColumnClass(
                        "latestBestEstimate",
                      )}`}
                    >
                      {FinanceUtils.formatCurrency(latestBestEstimate)}
                    </td>
                    {this.renderFutureSpacer()}
                  </React.Fragment>
                )
              }
            })}

            {this.renderCategoryReportTotal(toDateTotals, lifetimeTotals, category)}
          </tr>

          {this.renderCategoryLoan(category)}

          {this.props.showAccounts
            ? Object.values(category.categories).map((subCategory) =>
                this.renderAccount(category, subCategory, toDateTotals, lifetimeTotals),
              )
            : null}
        </React.Fragment>
      )
    }
  }

  renderCategoryLoan(category) {
    if (category.name === "Liability") {
      const categoryDetails = this.props.categories[category.name]
      if (categoryDetails.loan) {
        var forecast = 0
        var actual = 0
        var expected = 0
        var latestBestEstimate = 0
        return (
          <tr className="category cumulative">
            <th style={{ textAlign: "left" }} title={`${t("economics.labels.Liability Loans (Cumulative)")}`}>{`${t(
              "economics.labels.Cumulative Loans",
            )}`}</th>
            {this.props.report.dates.map((date, idx) => {
              Object.values(category.categories).map((account) => {
                const accountDetails = categoryDetails.categories[account.name]
                if (accountDetails.loan) {
                  // FIXME needs to be
                  forecast = CategoryUtils.add(
                    forecast,
                    this.getAccountTotal(this.props.report, "forecast", category.name, account.name, idx),
                  )
                  actual = CategoryUtils.add(
                    actual,
                    this.getAccountTotal(this.props.report, "actual", category.name, account.name, idx),
                  )
                  expected = CategoryUtils.add(
                    expected,
                    this.getAccountTotal(this.props.report, "expected", category.name, account.name, idx),
                  )
                  latestBestEstimate = CategoryUtils.add(
                    latestBestEstimate,
                    this.getAccountTotal(this.props.report, "latestBestEstimate", category.name, account.name, idx),
                  )
                }
              })

              const difference = actual - forecast
              if (idx < this.props.futureIdx) {
                return (
                  <React.Fragment key={idx}>
                    <td className={`cash-flow-forecast total ${this.getColumnClass("forecast")}`}>
                      {FinanceUtils.formatCurrency(forecast)}
                    </td>
                    <td className={`cash-flow-expected total ${this.getColumnClass("expected")}`}>
                      {FinanceUtils.formatCurrency(expected)}
                    </td>
                    <td className={`cash-flow-actual total ${this.getColumnClass("actual")}`}>
                      {FinanceUtils.formatCurrency(actual)}
                    </td>
                    <td className={`cash-flow-latestBestEstimate total ${this.getColumnClass("latestBestEstimate")}`}>
                      {FinanceUtils.formatCurrency(latestBestEstimate)}
                    </td>
                    <td className={`cash-flow-difference total ${this.getColumnClass("difference")}`}>
                      {FinanceUtils.formatCurrency(difference)}
                    </td>
                    {this.renderSpacer()}
                  </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={idx}>
                    <td className={`cash-flow-forecast total ${this.getColumnClass("forecast")}`}>
                      {FinanceUtils.formatCurrency(forecast)}
                    </td>
                    <td className={`cash-flow-latestBestEstimate total ${this.getColumnClass("latestBestEstimate")}`}>
                      {FinanceUtils.formatCurrency(latestBestEstimate)}
                    </td>
                    {this.renderFutureSpacer()}
                  </React.Fragment>
                )
              }
            })}

            {/* {this.renderCategoryReportTotal(toDateTotals, lifetimeTotals, category)} */}
          </tr>
        )
      }
    }
    return null
  }

  getCategoryLoanTotal(report, sectionName, categoryName, idx) {
    if (report[sectionName] && report[sectionName].categories[categoryName]) {
      return report[sectionName].categories[categoryName].totals[idx]
        ? report[sectionName].categories[categoryName].totals[idx]
        : 0
    }
    return 0
  }

  getCategoryTotal(report, sectionName, categoryName, idx) {
    if (report[sectionName] && report[sectionName].categories[categoryName]) {
      return report[sectionName].categories[categoryName].totals[idx]
        ? report[sectionName].categories[categoryName].totals[idx]
        : 0
    }
    return 0
  }

  renderAccount(category, account, toDateTotals, lifetimeTotals) {
    let actualProfitLoss =
      this.props.report.actual.categories[category.name] &&
      this.props.report.actual.categories[category.name].categories[account.name]
        ? this.props.report.actual.categories[category.name].categories[account.name]
        : []
    const forecastProfitLoss =
      this.props.report.forecast &&
      this.props.report.forecast.categories &&
      this.props.report.forecast.categories[category.name] &&
      this.props.report.forecast.categories[category.name].categories[account.name]
        ? this.props.report.forecast.categories[category.name].categories[account.name]
        : []
    let expectedProfitLoss =
      this.props.report.expected.categories[category.name] &&
      this.props.report.expected.categories[category.name].categories[account.name]
        ? this.props.report.expected.categories[category.name].categories[account.name]
        : []
    let latestBestEstimateProfitLoss =
      this.props.report.latestBestEstimate.categories[category.name] &&
      this.props.report.latestBestEstimate.categories[category.name].categories[account.name]
        ? this.props.report.latestBestEstimate.categories[category.name].categories[account.name]
        : []

    return (
      <React.Fragment key={account.name}>
        <tr className="profit-loss profit-loss-difference">
          <th style={{ textAlign: "left", paddingLeft: "2em" }} title={account.name}>{`${account.name}`}</th>
          {this.renderCategoryProfitLoss(
            forecastProfitLoss,
            expectedProfitLoss,
            actualProfitLoss,
            latestBestEstimateProfitLoss,
            Utils.sanitiseId(`${category.name}-${account.name}`),
          )}
          {this.renderCategoryReportTotal(toDateTotals, lifetimeTotals, category, account, undefined, actualProfitLoss)}
        </tr>

        {this.renderLoanDetails(
          category,
          account,
          forecastProfitLoss,
          expectedProfitLoss,
          actualProfitLoss,
          latestBestEstimateProfitLoss,
        )}

        {this.props.showAccounts && this.props.showSubAccounts
          ? Object.values(account.categories).map((subAccount) =>
              this.renderSubAccount(category, account, subAccount, toDateTotals, lifetimeTotals),
            )
          : null}
      </React.Fragment>
    )
  }

  renderLoanDetails(
    category,
    account,
    forecastProfitLoss,
    expectedProfitLoss,
    actualProfitLoss,
    latestBestEstimateProfitLoss,
  ) {
    const isLoan =
      category.name === "Liability" ? this.props.categories[category.name].categories[account.name].loan : false
    if (isLoan) {
      return (
        <React.Fragment key={account.name}>
          <tr className="profit-loss profit-loss-difference cumulative ">
            <th style={{ textAlign: "left" }} title={`${account.name} (Proceeds)`}>
              Loan Proceeds
            </th>
            {this.renderLoanAccount(
              forecastProfitLoss,
              expectedProfitLoss,
              actualProfitLoss,
              latestBestEstimateProfitLoss,
              true,
            )}
          </tr>

          <tr className="profit-loss profit-loss-difference cumulative ">
            <th style={{ textAlign: "left" }} title={`${account.name} (Debt Service)`}>
              Debt Service
            </th>
            {this.renderLoanAccount(
              forecastProfitLoss,
              expectedProfitLoss,
              actualProfitLoss,
              latestBestEstimateProfitLoss,
              false,
            )}
          </tr>

          <tr className="profit-loss profit-loss-difference cumulative ">
            <th style={{ textAlign: "left" }} title={`${account.name} (Cumulative)`}>{`Cumulative ${account.name}`}</th>
            {this.renderCumulativeCategoryProfitLoss(
              forecastProfitLoss,
              expectedProfitLoss,
              actualProfitLoss,
              latestBestEstimateProfitLoss,
              Utils.sanitiseId(account.name),
            )}
          </tr>
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  renderLoanAccount(forecastProfitLoss, expectedProfitLoss, actualProfitLoss, latestBestEstimateProfitLoss, proceeds) {
    let values = []

    var forecast = 0
    var actual = 0
    var expected = 0
    var latestBestEstimate = 0
    for (var idx = 0; idx < this.props.report.dates.length; idx++) {
      forecast = this.totalLoanTransactions(forecastProfitLoss, idx, proceeds)
      expected = this.totalLoanTransactions(expectedProfitLoss, idx, proceeds)
      actual = this.totalLoanTransactions(actualProfitLoss, idx, proceeds)
      latestBestEstimate = this.totalLoanTransactions(latestBestEstimateProfitLoss, idx, proceeds)

      values[idx] =
        idx < this.props.futureIdx ? (
          <React.Fragment key={idx}>
            <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
              {FinanceUtils.formatCurrency(forecast)}
            </td>
            <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>
              {FinanceUtils.formatCurrency(expected)}
            </td>
            <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>
              {FinanceUtils.formatCurrency(actual)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                "latestBestEstimate",
              )}`}
            >
              {FinanceUtils.formatCurrency(latestBestEstimate)}
            </td>
            <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
              {FinanceUtils.formatCurrency(actual - forecast)}
            </td>
            {this.renderSpacer()}
          </React.Fragment>
        ) : (
          <React.Fragment key={idx}>
            <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
              {FinanceUtils.formatCurrency(forecast)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                "latestBestEstimate",
              )}`}
            >
              {FinanceUtils.formatCurrency(latestBestEstimate)}
            </td>

            {this.renderFutureSpacer()}
          </React.Fragment>
        )
    }

    return values
  }

  totalLoanTransactions(profitLoss, idx, proceeds) {
    if (profitLoss.transactions && profitLoss.transactions[idx]) {
      return profitLoss.transactions[idx].reduce((total, transaction) => {
        if (transaction.type === "payment") {
          if (transaction.amount > 0 && proceeds) {
            return CategoryUtils.add(total, transaction.amount)
          } else if (transaction.amount < 0 && !proceeds) {
            return CategoryUtils.add(total, transaction.amount)
          }
        } else if (transaction.type === "transaction") {
          if (transaction.debit && !proceeds) {
            return CategoryUtils.add(total, transaction.debit)
          } else if (transaction.credit && proceeds) {
            return CategoryUtils.add(total, transaction.credit)
          }
        }
        return total
      }, 0)
    } else {
      return 0
    }
  }

  getAccountTotal(report, sectionName, categoryName, accountName, idx) {
    if (
      report[sectionName] &&
      report[sectionName].categories[categoryName] &&
      report[sectionName].categories[categoryName].categories[accountName]
    ) {
      return report[sectionName].categories[categoryName].categories[accountName].totals[idx]
        ? report[sectionName].categories[categoryName].categories[accountName].totals[idx]
        : 0
    }
    return 0
  }

  renderSubAccount(category, account, subAccount, toDateTotals, lifetimeTotals) {
    const forecastProfitLoss =
      this.props.report.forecast &&
      this.props.report.forecast.categories &&
      this.props.report.forecast.categories[category.name] &&
      this.props.report.forecast.categories[category.name].categories[account.name] &&
      this.props.report.forecast.categories[category.name].categories[account.name].categories[subAccount.name]
        ? this.props.report.forecast.categories[category.name].categories[account.name].categories[subAccount.name]
        : []
    let expectedProfitLoss =
      this.props.report.expected.categories[category.name] &&
      this.props.report.expected.categories[category.name].categories[account.name] &&
      this.props.report.expected.categories[category.name].categories[account.name].categories[subAccount.name]
        ? this.props.report.expected.categories[category.name].categories[account.name].categories[subAccount.name]
        : []
    let actualProfitLoss =
      this.props.report.actual.categories[category.name] &&
      this.props.report.actual.categories[category.name].categories[account.name] &&
      this.props.report.actual.categories[category.name].categories[account.name].categories[subAccount.name]
        ? this.props.report.actual.categories[category.name].categories[account.name].categories[subAccount.name]
        : []
    let latestBestEstimateProfitLoss =
      this.props.report.latestBestEstimate.categories[category.name] &&
      this.props.report.latestBestEstimate.categories[category.name].categories[account.name] &&
      this.props.report.latestBestEstimate.categories[category.name].categories[account.name].categories[
        subAccount.name
      ]
        ? this.props.report.latestBestEstimate.categories[category.name].categories[account.name].categories[
            subAccount.name
          ]
        : []

    return (
      <tr className="account profit-loss profit-loss-difference" key={`account-${subAccount.name}`}>
        <th style={{ textAlign: "left" }} title={subAccount.name}>
          {subAccount.name}
        </th>
        {this.renderCategoryProfitLoss(
          forecastProfitLoss,
          expectedProfitLoss,
          actualProfitLoss,
          latestBestEstimateProfitLoss,
          Utils.sanitiseId(`${category.name}-${account.name}-${subAccount.name}`),
        )}
        {this.renderCategoryReportTotal(toDateTotals, lifetimeTotals, category, account, subAccount, actualProfitLoss)}
      </tr>
    )
  }

  renderCategoryReportTotal(toDateTotals, lifetimeTotals, category, account, subAccount, actualProfitLoss) {
    const id = `tooltip-${Math.floor(Math.random() * 100000000)}`
    return (
      <React.Fragment>
        <td className={`cash-flow-forecast total ${this.getColumnClass("forecast")}`}>
          {FinanceUtils.formatCurrency(
            this.getCategoryReportTotal(toDateTotals, "forecast", category, account, subAccount),
          )}
        </td>
        <td className={`cash-flow-expected total ${this.getColumnClass("expected")}`}>
          {FinanceUtils.formatCurrency(
            this.getCategoryReportTotal(toDateTotals, "expected", category, account, subAccount),
          )}
        </td>
        <td id={id} className={`cash-flow-actual total ${this.getColumnClass("actual")}`}>
          {FinanceUtils.formatCurrency(
            this.getCategoryReportTotal(toDateTotals, "actual", category, account, subAccount),
          )}
          {!isEmpty(actualProfitLoss) && <ActualDetailsTooltip id={id} actualProfitLoss={actualProfitLoss} />}
        </td>
        <td className={`cash-flow-latestBestEstimate total ${this.getColumnClass("latestBestEstimate")}`}>
          {FinanceUtils.formatCurrency(
            this.getCategoryReportTotal(toDateTotals, "latestBestEstimate", category, account, subAccount),
          )}
        </td>

        <td className={`cash-flow-difference total ${this.getColumnClass("difference")}`}>
          {FinanceUtils.formatCurrency(
            this.getCategoryTotal(toDateTotals, "actual", category, account, subAccount) -
              this.getCategoryReportTotal(toDateTotals, "latestBestEstimate", category, account, subAccount),
          )}
        </td>

        <td className={`cash-flow-forecast total ${this.getColumnClass("forecast")}`}>
          {FinanceUtils.formatCurrency(
            this.getCategoryReportTotal(lifetimeTotals, "forecast", category, account, subAccount),
          )}
        </td>
        <td className={`cash-flow-latestBestEstimate total ${this.getColumnClass("latestBestEstimate")}`}>
          {FinanceUtils.formatCurrency(
            this.getCategoryReportTotal(lifetimeTotals, "latestBestEstimate", category, account, subAccount),
          )}
        </td>
      </React.Fragment>
    )
  }

  getCategoryReportTotal(totals, sectionName, category, account, subAccount) {
    if (category) {
      if (account) {
        if (subAccount) {
          return totals[sectionName].categories[category.name] &&
            totals[sectionName].categories[category.name].categories[account.name] &&
            totals[sectionName].categories[category.name].categories[account.name].categories[subAccount.name]
            ? totals[sectionName].categories[category.name].categories[account.name].categories[subAccount.name].totals
            : 0
        }
        return totals[sectionName].categories[category.name] &&
          totals[sectionName].categories[category.name].categories[account.name]
          ? totals[sectionName].categories[category.name].categories[account.name].totals
          : 0
      }
      return totals[sectionName].categories[category.name] ? totals[sectionName].categories[category.name].totals : 0
    }
    return 0
  }

  renderCategoryProfitLoss(
    forecastProfitLoss,
    expectedProfitLoss,
    actualProfitLoss,
    latestBestEstimateProfitLoss,
    idPrefix,
  ) {
    let values = []
    for (var idx = 0; idx < this.props.report.dates.length; idx++) {
      let forecast = forecastProfitLoss.totals
        ? forecastProfitLoss.totals[idx]
          ? forecastProfitLoss.totals[idx]
          : 0
        : 0
      let expected = expectedProfitLoss.totals && expectedProfitLoss.totals[idx] ? expectedProfitLoss.totals[idx] : 0
      let actual = actualProfitLoss.totals && actualProfitLoss.totals[idx] ? actualProfitLoss.totals[idx] : 0
      let latestBestEstimate =
        latestBestEstimateProfitLoss.totals && latestBestEstimateProfitLoss.totals[idx]
          ? latestBestEstimateProfitLoss.totals[idx]
          : 0

      values[idx] =
        idx < this.props.futureIdx ? (
          <React.Fragment key={idx}>
            <td
              className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}
              id={`${idPrefix}-forecast-cat-${idx}`}
            >
              {this.renderTransactionsTooltip(forecastProfitLoss, idx, `${idPrefix}-forecast-cat-${idx}`)}
              {FinanceUtils.formatCurrency(forecast)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}
              id={`${idPrefix}-expected-cat-${idx}`}
            >
              {this.renderTransactionsTooltip(expectedProfitLoss, idx, `${idPrefix}-expected-cat-${idx}`)}
              {FinanceUtils.formatCurrency(expected)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}
              id={`${idPrefix}-actual-cat-${idx}`}
            >
              {this.renderTransactionsTooltip(actualProfitLoss, idx, `${idPrefix}-actual-cat-${idx}`)}
              {FinanceUtils.formatCurrency(actual)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                "latestBestEstimate",
              )}`}
              id={`${idPrefix}-lbe-cat-${idx}`}
            >
              {this.renderTransactionsTooltip(latestBestEstimateProfitLoss, idx, `${idPrefix}-lbe-cat-${idx}`)}
              {FinanceUtils.formatCurrency(latestBestEstimate)}
            </td>
            <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
              {FinanceUtils.formatCurrency(actual - forecast)}
            </td>
            {this.renderSpacer()}
          </React.Fragment>
        ) : (
          <React.Fragment key={idx}>
            <td
              className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}
              id={`${idPrefix}-forecast-cat-${idx}`}
            >
              {this.renderTransactionsTooltip(forecastProfitLoss, idx, `${idPrefix}-forecast-cat-${idx}`)}
              {FinanceUtils.formatCurrency(forecast)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                "latestBestEstimate",
              )}`}
              id={`${idPrefix}-lbe-cat-${idx}`}
            >
              {this.renderTransactionsTooltip(latestBestEstimateProfitLoss, idx, `${idPrefix}-lbe-cat-${idx}`)}
              {FinanceUtils.formatCurrency(latestBestEstimate)}
            </td>

            {this.renderFutureSpacer()}
          </React.Fragment>
        )
    }

    return values
  }

  renderCumulativeCategoryProfitLoss(
    forecastProfitLoss,
    expectedProfitLoss,
    actualProfitLoss,
    latestBestEstimateProfitLoss,
    idPrefix,
  ) {
    let values = []

    var forecast = 0
    var actual = 0
    var expected = 0
    var latestBestEstimate = 0
    for (var idx = 0; idx < this.props.report.dates.length; idx++) {
      forecast = CategoryUtils.add(
        forecast,
        forecastProfitLoss.totals ? (forecastProfitLoss.totals[idx] ? forecastProfitLoss.totals[idx] : 0) : 0,
      )
      expected = CategoryUtils.add(
        expected,
        expectedProfitLoss.totals && expectedProfitLoss.totals[idx] ? expectedProfitLoss.totals[idx] : 0,
      )
      actual = CategoryUtils.add(
        actual,
        actualProfitLoss.totals && actualProfitLoss.totals[idx] ? actualProfitLoss.totals[idx] : 0,
      )
      latestBestEstimate = CategoryUtils.add(
        latestBestEstimate,
        latestBestEstimateProfitLoss.totals && latestBestEstimateProfitLoss.totals[idx]
          ? latestBestEstimateProfitLoss.totals[idx]
          : 0,
      )

      values[idx] =
        idx < this.props.futureIdx ? (
          <React.Fragment key={idx}>
            <td
              className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}
              id={`${idPrefix}-forecast-cumulative-${idx}`}
            >
              {this.renderTransactionsTooltip(forecastProfitLoss, idx, `${idPrefix}-forecast-cumulative-${idx}`)}
              {FinanceUtils.formatCurrency(forecast)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}
              id={`${idPrefix}-expected-cumulative-${idx}`}
            >
              {this.renderTransactionsTooltip(expectedProfitLoss, idx, `${idPrefix}-expected-cumulative-${idx}`)}
              {FinanceUtils.formatCurrency(expected)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}
              id={`${idPrefix}-actual-cumulative-${idx}`}
            >
              {this.renderTransactionsTooltip(actualProfitLoss, idx, `${idPrefix}-actual-cumulative-${idx}`)}
              {FinanceUtils.formatCurrency(actual)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                "latestBestEstimate",
              )}`}
              id={`${idPrefix}-lbe-cumulative-${idx}`}
            >
              {this.renderTransactionsTooltip(latestBestEstimateProfitLoss, idx, `${idPrefix}-lbe-cumulative-${idx}`)}
              {FinanceUtils.formatCurrency(latestBestEstimate)}
            </td>
            <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
              {FinanceUtils.formatCurrency(actual - forecast)}
            </td>
            {this.renderSpacer()}
          </React.Fragment>
        ) : (
          <React.Fragment key={idx}>
            <td
              className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}
              id={`${idPrefix}-forecast-cumulative-${idx}`}
            >
              {this.renderTransactionsTooltip(forecastProfitLoss, idx, `${idPrefix}-forecast-cumulative-${idx}`)}
              {FinanceUtils.formatCurrency(forecast)}
            </td>
            <td
              className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                "latestBestEstimate",
              )}`}
              id={`${idPrefix}-lbe-cumulative-${idx}`}
            >
              {this.renderTransactionsTooltip(latestBestEstimateProfitLoss, idx, `${idPrefix}-lbe-cumulative-${idx}`)}
              {FinanceUtils.formatCurrency(latestBestEstimate)}
            </td>

            {this.renderFutureSpacer()}
          </React.Fragment>
        )
    }

    return values
  }

  renderTransactionsTooltip(profitLoss, idx, targetId) {
    if (profitLoss.transactions && profitLoss.transactions[idx] && profitLoss.transactions[idx].length) {
      var showReading = false
      var showDate = false
      const transactions = profitLoss.transactions[idx].map((transaction) => {
        const transactionName = transaction.name ? transaction.name : "-"
        const date = transaction.date ? moment(transaction.date).format("DD/MMM/YY") : "-"
        const reading = transaction.reading ? EnergyUtils.formatWattHoursToPreferrence(transaction.reading) : "-"
        var amount
        if (transaction.type === "payment") {
          amount = FinanceUtils.formatCurrency(transaction.amount)
        } else if (transaction.type === "cost") {
          amount = FinanceUtils.formatCurrency(transaction.amount)
        } else if (transaction.type === "transaction") {
          amount =
            transaction.debit > 0
              ? FinanceUtils.formatCurrency(-transaction.debit)
              : "" + transaction.credit > 0
                ? FinanceUtils.formatCurrency(transaction.credit)
                : ""
        }

        if (transaction.date) {
          showDate = true
        }
        if (transaction.reading) {
          showReading = true
        }

        return { transactionName, date, reading, amount }
      })

      return (
        <UncontrolledTooltip target={targetId} key={targetId} placement="right">
          <table className="transactions-tooltip">
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>{t("table_headings.amount")}</th>
                {showDate ? <th style={{ textAlign: "left" }}>{t("table_headings.date")}</th> : null}
                {showReading ? <th style={{ textAlign: "left" }}>{t("table_headings.reading")}</th> : null}
                <th style={{ textAlign: "left" }}>{t("table_headings.details")}</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, idx) => {
                return (
                  <tr key={idx}>
                    <td>{transaction.amount}</td>
                    {showDate ? <td>{transaction.date}</td> : null}
                    {showReading ? <td>{transaction.reading}</td> : null}
                    <td>{transaction.transactionName}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </UncontrolledTooltip>
      )
    } else {
      return null
    }
  }

  render() {
    return <React.Fragment>{this.renderCategories()}</React.Fragment>
  }

  renderReportTotals(totalName) {
    return (
      <React.Fragment>
        <td className={` cash-flow-forecast ${this.getColumnClass("forecast")}`}>
          {FinanceUtils.formatCurrency(this.props.reportTotals.toDateTotals.forecast[totalName])}
        </td>
        <td className={` cash-flow-expected ${this.getColumnClass("expected")}`}>
          {FinanceUtils.formatCurrency(this.props.reportTotals.toDateTotals.expected[totalName])}
        </td>
        <td className={` cash-flow-actual ${this.getColumnClass("actual")}`}>
          {FinanceUtils.formatCurrency(this.props.reportTotals.toDateTotals.actual[totalName])}
        </td>
        <td className={` cash-flow-latestBestEstimate ${this.getColumnClass("latestBestEstimate")}`}>
          {FinanceUtils.formatCurrency(this.props.reportTotals.toDateTotals.latestBestEstimate[totalName])}
        </td>
        <td className={` cash-flow-difference ${this.getColumnClass("difference")}`}>
          {FinanceUtils.formatCurrency(
            this.props.reportTotals.toDateTotals.actual[totalName] -
              this.props.reportTotals.toDateTotals.latestBestEstimate[totalName],
          )}
        </td>

        <td className={` cash-flow-forecast ${this.getColumnClass("forecast")}`}>
          {FinanceUtils.formatCurrency(this.props.reportTotals.lifetimeTotals.forecast[totalName])}
        </td>
        <td className={` cash-flow-latestBestEstimate ${this.getColumnClass("latestBestEstimate")}`}>
          {FinanceUtils.formatCurrency(this.props.reportTotals.lifetimeTotals.latestBestEstimate[totalName])}
        </td>
      </React.Fragment>
    )
  }

  renderNetIncome() {
    return (
      <tr className={this.props.section}>
        <th style={{ textAlign: "left" }} id="net-income" title="Income - Expenses">
          Net Income
        </th>
        <UncontrolledTooltip target="net-income">Net Income = Income - Expense</UncontrolledTooltip>
        {this.props.report.forecast.netIncomes.map((cashFlow, idx) => {
          let forecast = cashFlow ? cashFlow : 0
          let latestBestEstimate =
            idx < this.props.report.latestBestEstimate.netIncomes.length
              ? this.props.report.latestBestEstimate.netIncomes[idx]
              : 0
          let expected =
            idx < this.props.report.expected.netIncomes.length ? this.props.report.expected.netIncomes[idx] : 0
          let actual = idx < this.props.report.actual.netIncomes.length ? this.props.report.actual.netIncomes[idx] : 0

          return idx < this.props.futureIdx ? (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>
                {FinanceUtils.formatCurrency(expected)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>
                {FinanceUtils.formatCurrency(actual)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
                {FinanceUtils.formatCurrency(actual - forecast)}
              </td>
              {this.renderSpacer()}
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate)}
              </td>
              {this.renderFutureSpacer()}
            </React.Fragment>
          )
        })}
        {this.renderReportTotals("netIncomes")}
      </tr>
    )
  }

  renderUnleveredTotals() {
    return (
      <tr className={this.props.section}>
        <th style={{ textAlign: "left" }} id="unlevered-totals">
          Unlevered Cash Flow
        </th>
        <UncontrolledTooltip target="unlevered-totals">
          Unlevered Cash Flow = Income - Expense - Fixed Asset
        </UncontrolledTooltip>
        {this.props.report.forecast.unleveredTotals.map((cashFlow, idx) => {
          let forecast = cashFlow ? cashFlow : 0
          let latestBestEstimate =
            idx < this.props.report.latestBestEstimate.unleveredTotals.length
              ? this.props.report.latestBestEstimate.unleveredTotals[idx]
              : 0
          let expected =
            idx < this.props.report.expected.unleveredTotals.length
              ? this.props.report.expected.unleveredTotals[idx]
              : 0
          let actual =
            idx < this.props.report.actual.unleveredTotals.length ? this.props.report.actual.unleveredTotals[idx] : 0

          return idx < this.props.futureIdx ? (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>
                {FinanceUtils.formatCurrency(expected)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>
                {FinanceUtils.formatCurrency(actual)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
                {FinanceUtils.formatCurrency(actual - forecast)}
              </td>
              {this.renderSpacer()}
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate)}
              </td>
              {this.renderFutureSpacer()}
            </React.Fragment>
          )
        })}
        {this.renderReportTotals("unleveredTotals")}
      </tr>
    )
  }

  renderUnleveredIrr() {
    return (
      <tr className={this.props.section}>
        <th style={{ textAlign: "left" }} id="unlevered-irr">
          Unlevered IRR
        </th>
        <UncontrolledTooltip target="unlevered-irr">
          Unlevered IRR = IRR calculated on: Income - Expense - Fixed Asset
        </UncontrolledTooltip>
        {this.props.report.forecast.unleveredIrr.map((cashFlow, idx) => {
          let forecast = cashFlow ? cashFlow : 0
          let latestBestEstimate =
            idx < this.props.report.latestBestEstimate.unleveredIrr.length
              ? this.props.report.latestBestEstimate.unleveredIrr[idx]
              : 0
          let expected =
            idx < this.props.report.expected.unleveredIrr.length ? this.props.report.expected.unleveredIrr[idx] : 0
          let actual =
            idx < this.props.report.actual.unleveredIrr.length ? this.props.report.actual.unleveredIrr[idx] : 0

          return idx < this.props.futureIdx ? (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatIRR(forecast)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>
                {FinanceUtils.formatIRR(expected)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>
                {FinanceUtils.formatIRR(actual)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatIRR(latestBestEstimate)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}
              ></td>
              {this.renderSpacer()}
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatIRR(forecast)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatIRR(latestBestEstimate)}
              </td>
              {this.renderFutureSpacer()}
            </React.Fragment>
          )
        })}
      </tr>
    )
  }

  renderLeveredTotals() {
    return (
      <tr className={this.props.section}>
        <th style={{ textAlign: "left" }} id="levered-totals">
          Levered Cash Flow
        </th>
        <UncontrolledTooltip target="levered-totals">
          Levered Cash Flow = Income - Expense - Fixed Asset +/- Liability
        </UncontrolledTooltip>
        {this.props.report.forecast.leveredTotals.map((cashFlow, idx) => {
          let forecast = cashFlow ? cashFlow : 0
          let latestBestEstimate =
            idx < this.props.report.latestBestEstimate.leveredTotals.length
              ? this.props.report.latestBestEstimate.leveredTotals[idx]
              : 0
          let expected =
            idx < this.props.report.expected.leveredTotals.length ? this.props.report.expected.leveredTotals[idx] : 0
          let actual =
            idx < this.props.report.actual.leveredTotals.length ? this.props.report.actual.leveredTotals[idx] : 0

          return idx < this.props.futureIdx ? (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>
                {FinanceUtils.formatCurrency(expected)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>
                {FinanceUtils.formatCurrency(actual)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
                {FinanceUtils.formatCurrency(actual - forecast)}
              </td>
              {this.renderSpacer()}
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate)}
              </td>
              {this.renderFutureSpacer()}
            </React.Fragment>
          )
        })}
        {this.renderReportTotals("leveredTotals")}
      </tr>
    )
  }

  renderLeveredIrr() {
    return (
      <tr className={this.props.section}>
        <th style={{ textAlign: "left" }} id="levered-irr">
          Levered IRR
        </th>
        <UncontrolledTooltip target="levered-irr">
          Levered IRR = IRR calculated on: Income - Expense - Fixed Asset +/- Liability
        </UncontrolledTooltip>
        {this.props.report.forecast.leveredIrr.map((cashFlow, idx) => {
          let forecast = cashFlow ? cashFlow : 0
          let latestBestEstimate =
            idx < this.props.report.latestBestEstimate.leveredIrr.length
              ? this.props.report.latestBestEstimate.leveredIrr[idx]
              : 0
          let expected =
            idx < this.props.report.expected.leveredIrr.length ? this.props.report.expected.leveredIrr[idx] : 0
          let actual = idx < this.props.report.actual.leveredIrr.length ? this.props.report.actual.leveredIrr[idx] : 0

          return idx < this.props.futureIdx ? (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatIRR(forecast)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>
                {FinanceUtils.formatIRR(expected)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>
                {FinanceUtils.formatIRR(actual)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatIRR(latestBestEstimate)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}
              ></td>
              {this.renderSpacer()}
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatIRR(forecast)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatIRR(latestBestEstimate)}
              </td>
              {this.renderFutureSpacer()}
            </React.Fragment>
          )
        })}
      </tr>
    )
  }

  renderRetainedEarnings() {
    return (
      <tr className={this.props.section}>
        <th style={{ textAlign: "left" }} id="retained-earnings">
          Retained Earnings
        </th>
        <UncontrolledTooltip target="retained-earnings">
          {`Net Present Value of the forecast Levered Cash Flows using the Discount Rate configured for this project ${
            this.props.project && this.props.project.discountRate
              ? `(${this.props.project.discountRate} %)`
              : `(default is ${this.props.projectDiscountRate}%)`
          }`}
        </UncontrolledTooltip>
        {this.props.report.forecast.retainedEarnings.map((cashFlow, idx) => {
          let forecast = cashFlow ? cashFlow : 0
          let latestBestEstimate =
            idx < this.props.report.latestBestEstimate.retainedEarnings.length
              ? this.props.report.latestBestEstimate.retainedEarnings[idx]
              : 0

          return idx < this.props.futureIdx ? (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>-</td>
              <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>-</td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
                -
              </td>
              {this.renderSpacer()}
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate)}
              </td>
              {this.renderFutureSpacer()}
            </React.Fragment>
          )
        })}
        {this.renderReportFutureOnlyTotals("retainedEarnings")}
      </tr>
    )
  }

  renderTotalValue() {
    const actualCumulativeTotal = this.props.report.actual.unleveredTotals.length
      ? this.props.report.actual.unleveredTotals.reduce((accumulator, total) => {
          return total ? accumulator + total : accumulator
        })
      : 0

    return (
      <tr className={this.props.section}>
        <th style={{ textAlign: "left" }} id="total-value">
          Total Value
        </th>
        <UncontrolledTooltip target="total-value">
          Historic Cumulative Unlevered Cash Flows plus the forecast Retained Earnings.
        </UncontrolledTooltip>
        {this.props.report.forecast.retainedEarnings.map((total, idx) => {
          let forecast = total ? total : 0
          let latestBestEstimate =
            idx < this.props.report.latestBestEstimate.retainedEarnings.length
              ? this.props.report.latestBestEstimate.retainedEarnings[idx]
              : 0

          return idx < this.props.futureIdx ? (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>-</td>
              <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>-</td>
              <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>-</td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                -
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
                -
              </td>
              {this.renderSpacer()}
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecast + actualCumulativeTotal)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimate + actualCumulativeTotal)}
              </td>
              {this.renderFutureSpacer()}
            </React.Fragment>
          )
        })}
        {this.renderReportFutureOnlyTotals("totalValue")}
      </tr>
    )
  }

  renderCumulativeTotal(report, title, totalKey) {
    var forecastCumulative = 0
    var actualCumulative = 0
    var expectedCumulative = 0
    var latestBestEstimateCumulative = 0

    return (
      <tr className={this.props.section}>
        <th style={{ textAlign: "left" }}>{title}</th>
        {this.props.report.forecast[totalKey].map((forecast, idx) => {
          forecastCumulative = CategoryUtils.add(forecastCumulative, forecast ? forecast : 0)
          expectedCumulative = CategoryUtils.add(
            expectedCumulative,
            report.expected[totalKey] && report.expected[totalKey][idx] ? report.expected[totalKey][idx] : 0,
          )
          actualCumulative = CategoryUtils.add(
            actualCumulative,
            report.actual[totalKey] && report.actual[totalKey][idx] ? report.actual[totalKey][idx] : 0,
          )
          latestBestEstimateCumulative = CategoryUtils.add(
            latestBestEstimateCumulative,
            report.latestBestEstimate[totalKey] && report.latestBestEstimate[totalKey]
              ? report.latestBestEstimate[totalKey][idx]
              : 0,
          )

          return idx < this.props.futureIdx ? (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecastCumulative)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>
                {FinanceUtils.formatCurrency(expectedCumulative)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>
                {FinanceUtils.formatCurrency(actualCumulative)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimateCumulative)}
              </td>
              <td className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>
                {FinanceUtils.formatCurrency(actualCumulative - latestBestEstimateCumulative)}
              </td>
              {this.renderSpacer()}
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <td className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>
                {FinanceUtils.formatCurrency(forecastCumulative)}
              </td>
              <td
                className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass(
                  "latestBestEstimate",
                )}`}
              >
                {FinanceUtils.formatCurrency(latestBestEstimateCumulative)}
              </td>
              {this.renderFutureSpacer()}
            </React.Fragment>
          )
        })}
      </tr>
    )
  }

  renderReportFutureOnlyTotals(totalName) {
    return (
      <React.Fragment>
        <td className={` cash-flow-forecast ${this.getColumnClass("forecast")}`}>-</td>
        <td className={` cash-flow-expected ${this.getColumnClass("expected")}`}>-</td>
        <td className={` cash-flow-actual ${this.getColumnClass("actual")}`}>-</td>
        <td className={` cash-flow-latestBestEstimate ${this.getColumnClass("latestBestEstimate")}`}>-</td>
        <td className={` cash-flow-difference ${this.getColumnClass("difference")}`}>-</td>

        <td className={` cash-flow-forecast ${this.getColumnClass("forecast")}`}>
          {FinanceUtils.formatCurrency(this.props.reportTotals.lifetimeTotals.forecast[totalName])}
        </td>
        <td className={` cash-flow-latestBestEstimate ${this.getColumnClass("latestBestEstimate")}`}>
          {FinanceUtils.formatCurrency(this.props.reportTotals.lifetimeTotals.latestBestEstimate[totalName])}
        </td>
      </React.Fragment>
    )
  }
}

export default CashFlowDifferences
