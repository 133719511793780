import React from "react"

import EcosuiteComponent from "@common/EcosuiteComponent"
import WidgetService from "./WidgetService"

import Logger from "@common/Logger"

import defaultWidgets from "./DefaultWidgets"

export default class InfoWidgetsSidebar extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      widgetList: "null",
    }
  }

  componentDidMount() {
    super.componentDidMount()
    WidgetService.getWidgetList(this.props.userId).then((data) => {
      if (data.widgets != null) {
        Logger.debug("List found!", data.widgets)
        this.setStateIfMounted({ widgetList: data.widgets.Attributes })
      } else {
        Logger.debug("loading default widgets", this.props.userId)
        this.setStateIfMounted({ widgetList: defaultWidgets.applications })
      }
    })
  }

  renderWidget(widget) {
    if (widget.enabled !== true) {
      return
    }
    return this.props.widgets[widget.name]
  }

  renderWidgets() {
    let sideBar = []
    if (this.state.widgetList !== "null") {
      const applicationIndex = this.state.widgetList.findIndex((e) => e.name === this.props.ecosuiteVeiw)
      this.state.widgetList[applicationIndex].widgets.forEach((widget) => {
        sideBar.push(this.renderWidget(widget))
      })
    }
    return <React.Fragment>{sideBar}</React.Fragment>
  }

  render() {
    return this.renderWidgets()
  }
}
