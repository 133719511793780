import React from "react"
import { Alert, Button } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { EcosuiteFieldTemplate } from "@common/form/EcosuiteForm"
import EcosuiteNumberField from "@common/form/EcosuiteNumberField"
import { MediaField } from "@dashboard/data/media/MediaField"
import FinanceService from "@dashboard/finance/FinanceService"
import Logger from "@common/Logger"
import { PaymentDocumentUploadArrayFieldTemplate } from "@common/form/ArrayFieldTemplates"
import i18n from "src/i18n"
import ExportCashFlowPaymentLogs from "./ExportCashFlowPaymentLogs"
import { produce } from "immer"

const jsprim = require("jsprim")
const { t } = i18n
export default class CashFlowPaymentLogs extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      keysToDelete: {},
    }

    this.toggleKeyToDelete = this.toggleKeyToDelete.bind(this)
    this.updateCashFlowPaymentLogs = this.updateCashFlowPaymentLogs.bind(this)
    this.paymentLogDocumentUploadArrayFieldTemplate = this.paymentLogDocumentUploadArrayFieldTemplate.bind(this)
  }

  async updateCashFlowPaymentLogs(e) {
    this.setStateIfMounted({ loading: true, error: null })

    let payments

    if (this.props.selectedYear) {
      payments = this.props.cashFlowPaymentLogs.paymentLogs.payments
      payments = payments.filter((payment) => payment.start.slice(0, 4) !== this.props.selectedYear)
      e.formData.forEach((payment) => payments.splice(0, 0, payment))
    } else {
      payments = e.formData
    }

    Object.keys(this.state.keysToDelete).forEach((key) => {
      payments = payments.filter((element) => element.id !== key)
    })

    this.setStateIfMounted({ keysToDelete: {} })

    const paymentLogs = jsprim.deepCopy(this.props.cashFlowPaymentLogs.paymentLogs)
    paymentLogs.payments = payments

    FinanceService.updateCashFlowPaymentLogs(
      this.props.project.code,
      this.props.cashFlowPaymentLogs.cashFlow.id,
      paymentLogs,
    )
      .then(() => {
        this.props.actions.updatePaymentLogs()
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ loading: false, error: err })
      })
  }

  getUiSchema() {
    return {
      "ui:options": {
        orderable: false,
      },
      items: {
        "ui:options": {
          label: false,
          inline: true,
          orderable: false,
        },
        start: {
          "ui:options": {
            inline: true,
          },
        },
        end: {
          "ui:options": {
            inline: true,
          },
        },
        reading: {
          "ui:options": {
            inline: true,
          },
        },
        expectedAmount: {
          "ui:options": {
            inline: true,
          },
        },
        advisedReading: {
          "ui:options": {
            inline: true,
          },
        },
        advisedAmount: {
          "ui:options": {
            inline: true,
          },
        },
        actualAmount: {
          "ui:options": {
            inline: true,
          },
        },
        description: {
          "ui:widget": "textarea",
          "ui:options": {
            label: false,
          },
          "ui:placeholder": "Description",
        },
      },
    }
  }

  /**
   * A custom payment log document upload template.
   * @param props - The props.
   * @returns {JSX.Element}
   */
  paymentLogDocumentUploadArrayFieldTemplate(props) {
    return PaymentDocumentUploadArrayFieldTemplate({
      ...props,
      projectId: this.props.project.code,
      cashFlowId: this.props.cashFlowPaymentLogs.cashFlow.id,
    })
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading message={t("loadingMsg.saving_cash_flow_payment_changes")} />
    }
    if (this.props.paymentLogsSchema && this.props.cashFlowPaymentLogs) {
      let filteredPayments = this.props.cashFlowPaymentLogs.paymentLogs.payments
      if (this.props.selectedYear) {
        filteredPayments = filteredPayments.filter((payment) => payment.start.slice(0, 4) === this.props.selectedYear)
      }
      return (
        <div className="ecogy-form">
          {this.state.error && <Alert color="danger">{this.state.error.message ?? this.state.error}</Alert>}
          <ExportCashFlowPaymentLogs
            data={this.props.cashFlowPaymentLogs.paymentLogs.payments || []}
            name={`${this.props.project.code}-${this.props.cashFlowPaymentLogs.cashFlow.account}-cashflowpayments`}
          />
          <EcosuiteForm
            schema={this.props.paymentLogsSchema.properties.payments} // We're only interested in the payments array part of the schema
            uiSchema={this.getUiSchema()}
            formData={filteredPayments}
            disabled={this.props.readonly}
            ArrayFieldTemplate={this.paymentLogDocumentUploadArrayFieldTemplate}
            FieldTemplate={EcosuiteFieldTemplate}
            fields={{ media: MediaField, NumberField: EcosuiteNumberField }}
            onSubmit={this.updateCashFlowPaymentLogs}
            formContext={{
              toggleKeyToDelete: this.toggleKeyToDelete,
              keysToDelete: this.state.keysToDelete,
            }}
          >
            <div>
              <Button color="primary" type="submit">
                {t("buttons.submit")}
              </Button>
            </div>
          </EcosuiteForm>
          {this.state.error && <Alert color="danger">{this.state.error.message ?? this.state.error}</Alert>}
        </div>
      )
    } else {
      return <Loading message={t("loadingMsg.saving_cash_flow_payment_details")} />
    }
  }

  toggleKeyToDelete(key) {
    const baseState = this.state.keysToDelete
    if (baseState[key]) {
      const newState = produce(baseState, (draft) => {
        delete draft[key]
      })
      this.setState({ keysToDelete: newState })
    } else {
      const newState = produce(baseState, (draft) => {
        draft[key] = true
      })
      this.setState({ keysToDelete: newState })
    }
  }
}
