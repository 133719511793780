import boltgif from "src/img/bolt-black.gif"
import React from "react"
import EcosuiteComponent from "@common/EcosuiteComponent"

export default class DamosWidget extends EcosuiteComponent {
  renderContent() {
    return (
      <div className="sidebar-heading" id={this.props.id}>
        <img src={boltgif} alt={"lighting bolt icon"} />
      </div>
    )
  }
}
