import * as React from "react"
import { Organization, useOrganization, useUpdateOrganization } from "../services/organization"
import { Button } from "@aws-amplify/ui-react"
import { useForm, SubmitHandler } from "react-hook-form"
import OrganizationFormFields from "./OrganizationFormFields"
import { toast } from "react-toastify"
import { formatData, formatForm } from "./helpers"
import SuAdminCreateUpdateLayout from "./SuperAdminCreateUpdateLayout"
import { S3LogoHandler } from "./S3LogoHandler"

interface Props {
  id: string
}

export default function SuAdminOrganizationDetails(props: Props) {
  const { id } = props
  const { data: thisOrg, isLoading, isError } = useOrganization(id)
  const { mutateAsync, isLoading: isUpdating } = useUpdateOrganization()
  const [submitting, setSubmitting] = React.useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setValue,
  } = useForm<Organization>({
    defaultValues: {},
  })

  React.useEffect(() => {
    if (id === thisOrg?.id) {
      reset(formatData(thisOrg))
    }
  }, [thisOrg, id])

  const onSubmit: SubmitHandler<Organization> = async (data) => {
    setSubmitting(true)
    const imageUrl = await S3LogoHandler(data.settings?.logoUrl)
    try {
      await mutateAsync(formatForm(data, imageUrl) as Organization)
      toast.success("Organization updated succesfully!")
      setSubmitting(false)
    } catch (e) {
      toast.error("Failed to update organization! ")
      setSubmitting(false)
    }
  }

  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error while fetching organizations</div>
  if (!thisOrg) return <div>No organization with id: {id} found!</div>

  return (
    <SuAdminCreateUpdateLayout header="Update Organization" onBack={() => reset()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ height: "86vh", overflowY: "auto" }}>
          <OrganizationFormFields
            control={control}
            errors={errors}
            organization={thisOrg}
            reset={reset}
            setValue={setValue}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px", paddingRight: "12px" }}>
          <Button
            variation="primary"
            onClick={handleSubmit(onSubmit)}
            isLoading={submitting}
            loadingText="Updating organization..."
            isDisabled={!isDirty}
          >
            Update Organization
          </Button>
        </div>
      </form>
    </SuAdminCreateUpdateLayout>
  )
}
