
import API from "@common/API"

const WidgetService = {
    async getWidgetListSchema() {
        return API.getFromCache("/schemas/user-widget-list")
    },

    async createWidgetList(userId: string , formData: unknown ): Promise<unknown> {
        return API.post("/widget-list/" + userId , formData)
    },

    async getWidgetList(userId: string) : Promise<unknown> {
        return API.get("/widget-list/" + userId)
    },

    async editWidgetList(userId: string , formData: unknown ): Promise<unknown> {
        return API.put("/widget-list/" + userId , formData)
    },
}

export default WidgetService
