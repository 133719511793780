import React from "react"
import Moment from "moment"
import { extendMoment } from "moment-range"

import EcosuiteComponent, { EcosuiteComponentError, Error } from "@common/EcosuiteComponent"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import Aggregations from "@common/Aggregations"
import Logger from "@common/Logger"

import UserAlertService from "@dashboard/event/UserAlertService"
import EnergyService from "@dashboard/energy/EnergyService"

import EconodeModule from "../EconodeModule"

import EconodeEnergyDashboard from "./views/EconodeEnergyDashboard"
import EconodeEnergyMonitorView from "./views/EconodeEnergyMonitorView"

import EnergyProjectMapView from "./views/EconodeEnergyMapProjectView"
import EnergyProjectLiveView from "./views/EconodeEnergyLiveProjectView"
import EnergyProjectSourceView from "./views/EconodeEnergySourceProjectView"
import EnergyProjectSourceGraphsView from "./views/EconodeEnergySourceGraphsProjectView"
import EconodeEnergyDashboardProjectView from "./views/EconodeEnergyDashboardProjectView"
import EconodeMonitorView from "./views/EconodeEnergyMonitorView"
import EconodePowerDashboardView from "./views/EconodePowerDashboardView"
import EconodeSavingsGoalProjectView from "./views/EconodeSavingsGoalProjectView"

import "@dashboard/energy/EnergyModule.css"
import "./EconodeEnergy.css"
import i18n from "src/i18n"

const { t } = i18n
const moment = extendMoment(Moment)

class EnergyModule extends EconodeModule {
  constructor(props) {
    super(props, "econode-energy")

    this.state.dateRangeIncludesTime = true
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadRange(this.getExclusiveRange(), true)
    this.loadInstantaneous()
    this.loadEnergyStatus()

    let interval = setInterval(() => {
      this.loadInstantaneous()
      this.loadEnergyStatus()
    }, 60000) // We update the "live" data every regularly, 60000 = 5 minutes
    this.setStateIfMounted({ interval: interval })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    // We recalculate the date range for "All Time" so that the graphs can be updated if necessary
    this.recalculateAllTimeRange(prevProps)

    if (this.props.loadTime !== prevProps.loadTime) {
      this.loadRange(this.getExclusiveRange())

      this.loadInstantaneous()
      this.loadEnergyStatus()
    } else if (this.props.project !== prevProps.project) {
      this.loadRange(this.getExclusiveRange())
    }
  }

  getProducts() {
    return []
  }

  getSelectedProductIds() {
    return []
  }

  getAggregateForRange(range) {
    let aggregate = DateRangeUtils.getAggregateForRange(range)
    if (range.diff("days", true) <= 1) {
      aggregate = Aggregations.FifteenMinute
    }
    return aggregate
  }

  selectRange(rangeName, customRange) {
    let range = super.selectRange(rangeName, customRange)
    this.loadRange(range)
  }

  loadRange(range) {
    const aggregate = this.getAggregateForRange(range)

    this.loadConsumptionDatums(range, aggregate)
    this.loadPredictedConsumption(range, aggregate)
    this.loadEnergyReadings(range, aggregate)
    this.loadPreviousEnergyReadings(range, aggregate)
    this.loadUserAlerts(range)
  }

  loadUserAlerts(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        userAlerts: undefined,
      },
      () => {
        UserAlertService.getUserAlerts(this.props.userId, range)
          .then((alerts) => {
            if (this.isRangeCurrent(range)) {
              this.setStateIfMounted({
                userAlerts: alerts,
              })
            } else {
              Logger.debug(`Ignoring out of date response for range: ${range}`)
            }
          })
          .catch((err) => {
            Logger.error(err)
            if (this.isRangeCurrent(range)) {
              this.setStateIfMounted({
                userAlerts: new EcosuiteComponentError(err),
              })
            }
          })
      },
    )
  }

  loadConsumptionDatums(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        datums: undefined,
        datumsError: undefined,
      },
      () => {
        if (this.props.project) {
          EnergyService.getProjectConsumptionDatums(range, aggregate, this.props.project.code)
            .then((response) => {
              if (this.isRangeCurrent(range)) {
                this.setStateIfMounted({
                  datums: response,
                })
              } else {
                Logger.debug(`Ignoring out of date response for range: ${range}`)
              }
            })
            .catch((err) => {
              Logger.error(err)
              if (this.isRangeCurrent(range)) {
                this.setStateIfMounted({
                  datums: new EcosuiteComponentError(err),
                })
              }
            })
        }
      },
    )
  }

  loadPredictedConsumption(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      predictedConsumption: undefined,
    })

    EnergyService.getPredictedConsumption(range, aggregate)
      .then((response) => {
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            predictedConsumption: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            predictedConsumption: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadEnergyReadings(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      readings: undefined,
    })

    EnergyService.getEnergyReadings(range)
      .then((response) => {
        if (this.isRangeCurrent(range, aggregate)) {
          this.setStateIfMounted({
            readings: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            readings: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadPreviousEnergyReadings(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      actualRange: undefined,
      previousRange: undefined,
      previousReadings: undefined,
    })

    const now = moment()
    if (range.start.isAfter(now)) {
      // We set to null to indicate that there is no actual/previous data to load for the selected dates
      this.setStateIfMounted({
        actualRange: null,
        previousRange: null,
        previousReadings: null,
      })
    } else {
      let actualRange = moment.range(
        range.start,
        range.end.isAfter(now) ? now.add(1, aggregate).startOf(aggregate) : range.end,
      )
      let rangeLength = actualRange.end - actualRange.start
      let previousRange = moment.range(actualRange.start - rangeLength, actualRange.end - rangeLength)

      EnergyService.getEnergyReadings(previousRange)
        .then((response) => {
          if (this.isRangeCurrent(range)) {
            this.setStateIfMounted({
              actualRange: actualRange,
              previousRange: previousRange,
              previousReadings: response,
            })
          } else {
            Logger.debug(`Ignoring out of date response for range: ${range}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          if (this.isRangeCurrent(range)) {
            this.setStateIfMounted({
              actualRange: actualRange,
              previousRange: previousRange,
              previousReadings: new EcosuiteComponentError(err),
            })
          }
        })
    }
  }

  loadInstantaneous() {
    EnergyService.getInstantaneous()
      .then((response) => {
        this.setStateIfMounted({
          instantaneous: response,
        })
      })
      .catch((err) => {
        this.setStateIfMounted({
          instantaneous: new EcosuiteComponentError(err),
        })
      })
  }

  loadEnergyStatus() {
    EnergyService.getStatus()
      .then((response) => {
        this.setStateIfMounted({
          projectsStatus: response,
        })
      })
      .catch((err) => {
        this.setStateIfMounted({
          projectsStatus: new EcosuiteComponentError(err),
        })
      })
  }

  getProjectStatus(project) {
    if (this.isContentValid(this.state.projectsStatus)) {
      return this.state.projectsStatus.projects.find((projectStatus) => {
        return projectStatus.code === project.code
      })
    }
    return this.state.projectsStatus
  }

  renderProjectView() {
    const project = this.props.project
    return (
      <div className="EnergyView">
        <EnergyProjectViews
          restrictions={this.props.restrictions}
          groups={this.props.groups}
          view={this.state.projectView}
          range={this.getExclusiveRange()}
          rangeName={this.state.rangeName}
          customRange={this.getCustomRange()}
          actualRange={this.state.actualRange}
          previousRange={this.state.previousRange}
          selectRange={this.selectRange}
          project={project}
          projects={[project]}
          projectStatus={this.getProjectStatus(project)}
          datumsRange={this.isContentValid(this.state.datums) ? this.state.datums.range : this.state.datums}
          datumsAggregation={this.isContentValid(this.state.datums) ? this.state.datums.aggregation : this.state.datums}
          datums={this.isContentValid(this.state.datums) ? this.state.datums.projectDatums : this.state.datums}
          predictedConsumption={
            this.isContentValid(this.state.predictedConsumption)
              ? this.state.predictedConsumption.projects[project.code]
              : this.state.predictedConsumption
          }
          instantaneous={
            this.isContentValid(this.state.instantaneous)
              ? this.state.instantaneous.projects[project.code]
              : this.state.instantaneous
          }
          instantaneousTimestamp={
            this.isContentValid(this.state.instantaneous)
              ? this.state.instantaneous.timestamp
              : this.state.instantaneous
          }
          readings={
            this.isContentValid(this.state.readings) ? this.state.readings.projects[project.code] : this.state.readings
          }
          previousReadings={
            this.isContentValid(this.state.previousReadings)
              ? this.state.previousReadings.projects[project.code]
              : this.state.previousReadings
          }
          lastMonthsEnergyReadings={
            this.isContentValid(this.state.lastMonthsEnergyReadings)
              ? this.state.lastMonthsEnergyReadings.projects[project.code]
              : this.state.lastMonthsEnergyReadings
          }
          userAlerts={this.state.userAlerts}
          showConsumption={true} //this.isConsumptionVisible()
          showStorage={this.isStorageVisible()}
          updateProject={this.props.updateProject}
        />
      </div>
    )
  }

  renderModuleView() {
    return (
      <div className="EnergyView">
        <EnergyViews
          restrictions={this.props.restrictions}
          groups={this.props.groups}
          view={this.state.moduleView}
          range={this.getExclusiveRange()}
          rangeName={this.state.rangeName}
          customRange={this.getCustomRange()}
          actualRange={this.state.actualRange}
          previousRange={this.state.previousRange}
          selectRange={this.selectRange}
          projects={this.props.projects}
          predictedConsumption={this.state.predictedConsumption}
          projectsStatus={this.state.projectsStatus}
          readings={this.state.readings}
          previousReadings={this.state.previousReadings}
          showGeneration={this.isGenerationVisible()}
          showConsumption={true} //this.isConsumptionVisible()
          showStorage={this.isStorageVisible()}
          userId={this.props.userId}
        />
      </div>
    )
  }
}

class EnergyViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "dashboard":
        return <EconodeEnergyDashboard {...this.props} />
      case "monitor":
        return <EconodeEnergyMonitorView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ` + this.props.view }} />
    }
  }
}

class EnergyProjectViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "graphs":
        return <EconodeEnergyDashboardProjectView {...this.props} />
      case "savings":
        return <EconodeSavingsGoalProjectView {...this.props} />
      case "map":
        return <EnergyProjectMapView {...this.props} />
      case "live":
        return <EnergyProjectLiveView {...this.props} />
      case "source":
        return <EnergyProjectSourceView {...this.props} />
      case "sourceGraphs":
        return <EnergyProjectSourceGraphsView {...this.props} />
      case "monitor":
        return <EconodeMonitorView {...this.props} />
      case "power":
        return <EconodePowerDashboardView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ` + this.props.view }} />
    }
  }
}

export default EnergyModule
