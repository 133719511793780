import React from "react"
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Alert } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import SystemDetails from "./SystemDetails"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
import EnergyService from "@dashboard/energy/EnergyService"

const { t } = i18n
class SiteSytems extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.systemAdded = this.systemAdded.bind(this)
    this.systemChanged = this.systemChanged.bind(this)
    this.systemDeleted = this.systemDeleted.bind(this)
    this.state = {}
  }

  componentDidMount() {
    super.componentDidMount()
    if (this.props.site) {
      this.selectDefaultSystem()
      this.loadDevices()
    }
  }

  componentDidUpdate(prevProps) {
    let activeTab = this.state.activeTab
    if (
      this.props.site.code !== prevProps.site.code ||
      (activeTab && activeTab !== "new-system" && !this.props.site.systems[activeTab])
    ) {
      this.selectDefaultSystem()
    }
  }

  async loadDevices() {
    EnergyService.getDevicesNoChache()
      .then((response) => {
        this.setState({ projectDevices: response[this.props.project.code] })
      })
      .catch((error) => {
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  selectDefaultSystem() {
    if (Object.keys(this.props.site.systems).length > 0) {
      this.setState({
        activeTab: this.getSystems()[0].code,
      })
    } else {
      this.setState({
        activeTab: "new-system",
      })
    }
  }

  getSystems() {
    return Object.values(this.props.site.systems).sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  async systemAdded(system) {
    await this.props.systemAdded(system)
    this.setState({
      activeTab: system.code,
    })
  }

  systemChanged(system) {
    this.props.systemChanged(system)
  }

  systemDeleted(system) {
    this.props.systemDeleted(system)
    this.selectDefaultSystem()
  }

  renderContent() {
    if (this.props.site) {
      let systems = this.getSystems()

      return (
        <div className="site-system-tabs">
          <Nav tabs>
            {systems.map((system, idx) => {
              return (
                <NavItem key={"tab-system-" + idx}>
                  <NavLink
                    className={this.state.activeTab === system.code ? "active" : null}
                    onClick={() => {
                      this.toggle(system.code)
                    }}
                    id={system.code}
                  >
                    {system.name}
                    {system.verified && <Icon icon="verified" className="verified" />}
                  </NavLink>
                  <UncontrolledTooltip target={system.code}>{this.props.verifiedTooltip}</UncontrolledTooltip>
                </NavItem>
              )
            })}

            {this.props.readonly ? null : (
              <NavItem key={"tab-new-system"} className="ms-auto new">
                <NavLink
                  className={this.state.activeTab === "new-system" ? "active" : null}
                  onClick={() => {
                    this.toggle("new-system")
                  }}
                >
                  {t("labels.new_system")}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            {systems.length > 0 ? (
              systems.map((system) => {
                return (
                  <TabPane tabId={system.code} key={"tab-content-" + system.code}>
                    <Row>
                      <Col sm="12">
                        <SystemDetails
                          project={this.props.project}
                          site={this.props.site}
                          system={system}
                          predictedGeneration={
                            this.props.predictedGeneration ? this.props.predictedGeneration.systems[system.code] : null
                          }
                          systemChanged={this.systemChanged}
                          systemDeleted={this.systemDeleted}
                          autoCompletes={this.props.autoCompletes}
                          readonly={this.props.readonly}
                          projectSNDevices={this.state.projectDevices}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )
              })
            ) : (
              <div className="site-systems">
                <Alert color="warning">{t("alertsInfo.site_has_no_system")}</Alert>
              </div>
            )}
            <TabPane tabId="new-system" key={"tab-content-new-system"}>
              <Row>
                <Col sm="12">
                  <SystemDetails
                    project={this.props.project}
                    site={this.props.site}
                    systemAdded={this.systemAdded}
                    autoCompletes={this.props.autoCompletes}
                    readonly={this.props.readonly}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      )
    }

    return <Loading />
  }
}

export default SiteSytems
