import { Button, Col, Nav, NavLink, Row, TabContent, TabPane, Progress } from "reactstrap"
import EcosuiteForm from "@common/form/EcosuiteForm"
import React, { ReactElement, useState, useEffect } from "react"
import {
  ChecklistDataContainer,
  ChecklistSchemaContainer,
  ChecklistTab,
  indexToTab,
} from "@dashboard/process/views/checklists/ChecklistView"
import i18n from "src/i18n"
import { convertSchemaToChecklistFormData, overwriteProjectChecklist } from "./ChecklistService"
import { Loading } from "@common/EcosuiteComponent"
import _, { cloneDeep } from "lodash"
import BackdropDialog, { BackdropDialogFunction } from "@common/input/button/BackdropDialog"
import { importToChecklist } from "@dashboard/process/views/checklists/ImportService"
import Icon from "@common/display/Icon"
import { generateUiSchema } from "@dashboard/process/views/checklists/ChecklistUiSchema"
import { produce } from "immer"
import { generateConfetti } from "@dashboard/data/project/confetti"
import ShareChecklist from "src/PublicView/Checklist/ShareChecklist"
import { useGetLastUpdatedChecklistUser } from "src/services/checklist"
import moment from "moment"
import RecordService, { RecordItem } from "./RecordService"
import API from "@common/API"
import { Dialog, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogClose } from "src/components/ui/dialog"
import { Checkbox } from "src/components/ui/checkbox"
import { Spinner } from "src/components/ui/spinner"
import { Button as ShadButton } from "src/components/ui/button"
import { toast } from "react-toastify"
const { t } = i18n

interface SuggestionItem {
  tab: ChecklistTab
  sectionKey: string
  key: string
  title: string
  suggestedId: string | null
  suggestedName: string
  apply: boolean
}

/**
 * The checklist form props.
 */
interface ChecklistFormProps {
  code: string
  state: string
  tab: ChecklistTab
  setTab: (tab: ChecklistTab) => void
  checklistSchemas: ChecklistSchemaContainer
  checklistData: ChecklistDataContainer
  editedChecklistData: ChecklistDataContainer
  setEditedChecklistData: (container: ChecklistDataContainer) => void
  refreshChecklistSchemas: () => void
  refreshChecklistData: () => void
  checklistTabs: Array<"NTP" | "PTO" | "ICR">
}

/**
 * Suggest a record for a given item using the existing API endpoint.
 */
async function suggestRecord(
  records: RecordItem[],
  itemTitle: string,
  itemData: string,
  itemNotes: string,
): Promise<string | null> {
  try {
    const data = (await API.post(`/records/suggestChecklistRecord`, {
      records: records.map((r) => ({ id: r.id, name: r.name })),
      itemTitle,
      itemData,
      itemNotes,
    })) as { id: string }
    return data.id
  } catch (error) {
    return null
  }
}

/**
 * The checklist form.
 */
export const ChecklistForm = (props: ChecklistFormProps) => {
  const { checklistTabs } = props

  const [isActioning, setIsActioning] = useState<boolean>(false)
  const [isImportDialogOpen, setIsImportDialogOpen] = useState<boolean>(false)
  const [confetti, setConfetti] = useState(false)
  const [records, setRecords] = useState<RecordItem[]>([])
  const [recordsLoading, setRecordsLoading] = useState<boolean>(true)

  const { data: lastUpdatedUser } = useGetLastUpdatedChecklistUser(props.code)

  useEffect(() => {
    RecordService.listRecords().then((data) => {
      setRecords(data.filter((r) => r.path.split("/")[1] === props.code))
      setRecordsLoading(false)
    })
  }, [props.code])

  const activateCelebration = () => {
    setConfetti(true)
    setTimeout(() => {
      setConfetti(false)
    }, 3000)
  }

  /**
   * Save all checklists.
   */
  async function saveAllChecklists(): Promise<void> {
    const ntpEdit = props.editedChecklistData.ntpData
    const ptoEdit = props.editedChecklistData.ptoData
    const icrEdit = props.editedChecklistData.icrData

    const todo: Promise<unknown>[] = []
    if (!_.isEqual(ntpEdit, props.checklistData.ntpData)) {
      todo.push(overwriteProjectChecklist(props.code, ChecklistTab.NTP, ntpEdit))
    }
    if (!_.isEqual(ptoEdit, props.checklistData.ptoData)) {
      todo.push(overwriteProjectChecklist(props.code, ChecklistTab.PTO, ptoEdit))
    }
    if (!_.isEqual(icrEdit, props.checklistData.icrData)) {
      todo.push(overwriteProjectChecklist(props.code, ChecklistTab.ICR, icrEdit))
    }

    if (todo.length > 0) {
      setIsActioning(true)
      await Promise.all(todo)
      await props.refreshChecklistData()
      setIsActioning(false)
    }

    return
  }

  function alphabeticallyOrderUiSchema(schema: any) {
    if (!schema || !schema.properties) {
      return schema
    }
    const r = produce(schema, (draft: any) => {
      Object.keys(draft.properties).forEach((item) => {
        draft.properties[item]["ui:order"] = [...Object.keys(draft.properties[item].properties).sort()]
      })
    })
    return cloneDeep(r)
  }

  /**
   * Get the tab schema
   */
  function getTabSchema(tab: ChecklistTab): Schema | undefined {
    switch (tab) {
      case ChecklistTab.NTP:
        return alphabeticallyOrderUiSchema(props.checklistSchemas.ntpSchema)
      case ChecklistTab.PTO:
        return alphabeticallyOrderUiSchema(props.checklistSchemas.ptoSchema)
      case ChecklistTab.ICR:
        return alphabeticallyOrderUiSchema(props.checklistSchemas.icrSchema)
    }
  }

  /**
   * Get the tab data.
   */
  function getTabData(tab: ChecklistTab): unknown | undefined {
    switch (tab) {
      case ChecklistTab.NTP:
        return props.editedChecklistData.ntpData
      case ChecklistTab.PTO:
        return props.editedChecklistData.ptoData
      case ChecklistTab.ICR:
        return props.editedChecklistData.icrData
    }
  }

  function checkListProgress(schema: any, formData: any) {
    let tickedBoxes = 0
    let totalProperties = 0
    if (!formData || !schema || !schema.properties) {
      return 0
    }
    Object.keys(schema.properties).forEach((key) => {
      if (formData[key] !== undefined) {
        Object.keys(schema.properties[key].properties).forEach((subKey) => {
          if (formData[key][subKey] !== undefined) {
            if (formData[key][subKey].isChecked) {
              ++tickedBoxes
            }
            ++totalProperties
          }
        })
      }
    })
    if (tickedBoxes > 0) {
      return Math.round((tickedBoxes / totalProperties) * 100)
    }
    return 0
  }

  /**
   * Build the body for a tab.
   */
  function buildChecklistBody(tab: ChecklistTab): ReactElement {
    const schema = getTabSchema(tab)
    const editing = getTabData(tab)

    let body
    if (isActioning) {
      body = <Loading />
    } else if (!schema || !schema.properties) {
      body = <EmptyChecklistPrompt toggle={() => setIsImportDialogOpen(!isImportDialogOpen)} />
    } else {
      delete schema["title"]
      body = (
        <div className="ecogy-form" style={{ overflow: "scroll", height: "100%" }}>
          <EcosuiteForm
            schema={schema}
            formData={editing}
            formContext={{
              checklistType: tab,
              records,
              isNtpOrPto: tab === ChecklistTab.NTP || tab === ChecklistTab.PTO,
            }}
            uiSchema={generateUiSchema(schema, tab)}
            onChange={(e) => {
              if (checkListProgress(schema, e.formData) === 100) {
                activateCelebration()
              }
              switch (tab) {
                case ChecklistTab.NTP:
                  props.setEditedChecklistData({
                    ...props.editedChecklistData,
                    ntpData: e.formData,
                  })
                  break
                case ChecklistTab.PTO:
                  props.setEditedChecklistData({
                    ...props.editedChecklistData,
                    ptoData: e.formData,
                  })
                  break
                case ChecklistTab.ICR:
                  props.setEditedChecklistData({
                    ...props.editedChecklistData,
                    icrData: e.formData,
                  })
                  break
              }
            }}
            onSubmit={saveAllChecklists}
          >
            <Row className="ecogy-form-buttons">
              <Col className="button-section" sm="2">
                <Button size="sm" color={"primary"} type="submit" onSubmit={saveAllChecklists}>
                  {t("buttons.save")}
                </Button>
                <Button size="sm" color={"primary"} type="submit" onClick={() => setIsImportDialogOpen(true)}>
                  {t("buttons.import")}
                </Button>
                <ShareChecklist
                  metaData={props.editedChecklistData}
                  schema={{
                    ntpSchema: alphabeticallyOrderUiSchema(props.checklistSchemas.ntpSchema),
                    icrSchema: alphabeticallyOrderUiSchema(props.checklistSchemas.icrSchema),
                    ptoSchema: alphabeticallyOrderUiSchema(props.checklistSchemas.ptoSchema),
                  }}
                  checklistTabs={checklistTabs}
                />
              </Col>
            </Row>
          </EcosuiteForm>
        </div>
      )
    }

    return body
  }

  /**
   * The single "Generate All Suggestions" logic:
   */
  const [showSuggestionsDialog, setShowSuggestionsDialog] = useState(false)
  const [isGeneratingSuggestions, setIsGeneratingSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState<SuggestionItem[]>([])
  const [completedCount, setCompletedCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [noSuggestionCount, setNoSuggestionCount] = useState(0)

  // New states for handling no-record and low-record scenarios
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showWarning, setShowWarning] = useState<boolean>(false)

  async function handleGenerateAllSuggestions() {
    setShowSuggestionsDialog(true)
    // If records still loading, just show dialog with loading state.
    // Once records finish loading, the useEffect below will handle logic.
  }

  // Once records have finished loading, if the dialog is open and no checks have run, run checks:
  useEffect(() => {
    if (
      !recordsLoading &&
      showSuggestionsDialog &&
      !isGeneratingSuggestions &&
      suggestions.length === 0 &&
      !errorMessage &&
      !showWarning
    ) {
      // Now that we know records are loaded and the dialog is open, let's do the checks:
      if (records.length === 0) {
        setErrorMessage("No records available. Cannot generate suggestions.")
        toast.error("No records available. Cannot generate suggestions.", {
          position: "top-right",
          autoClose: 5000,
          theme: "light",
        })
        return
      }

      if (records.length < 5) {
        setShowWarning(true)
        return
      }

      // If we have enough records
      generateSuggestions()
    }
  }, [recordsLoading, showSuggestionsDialog])

  async function generateSuggestions() {
    setErrorMessage(null)
    setShowWarning(false)
    setIsGeneratingSuggestions(true)

    const allItems: {
      tab: ChecklistTab
      key: string
      title: string
      data: string
      notes: string
      sectionKey: string
    }[] = []
    for (const tab of checklistTabs.filter((t) => t !== "ICR")) {
      const enumTab = ChecklistTab[tab]
      const schema = getTabSchema(enumTab)
      const formData = getTabData(enumTab) as Record<string, any>
      const defaultformDataFromSchema = convertSchemaToChecklistFormData(schema)

      if (schema && schema.properties && defaultformDataFromSchema) {
        const sortedKeys = Object.keys(schema.properties)
        for (const secKey of sortedKeys) {
          const section = formData[secKey]
          const sectionFromSchema = defaultformDataFromSchema[secKey]

          if (sectionFromSchema) {
            Object.keys(sectionFromSchema).forEach((subKey) => {
              const item = section[subKey]
              const itemTitle = item.title || subKey
              const itemData = item.data || ""
              const itemNotes = item.notes || ""
              allItems.push({
                tab: enumTab,
                key: subKey,
                title: itemTitle,
                data: itemData,
                notes: itemNotes,
                sectionKey: secKey,
              })
            })
          }
        }
      }
    }

    setTotalCount(allItems.length)
    setCompletedCount(0)
    setNoSuggestionCount(0)

    const suggestionPromises = allItems.map(async (item) => {
      const suggestedId = await suggestRecord(records, item.title, item.data, item.notes)
      const suggestedName = suggestedId
        ? records.find((r) => r.id === suggestedId)?.name || suggestedId
        : "No suggestion available"
      setCompletedCount((prev) => prev + 1)
      if (!suggestedId) {
        setNoSuggestionCount((prev) => prev + 1)
      }

      return {
        tab: item.tab,
        sectionKey: item.sectionKey,
        key: item.key,
        title: item.title,
        suggestedId,
        suggestedName,
        apply: true,
      } as SuggestionItem
    })

    const results = await Promise.all(suggestionPromises)
    setSuggestions(results)
    setIsGeneratingSuggestions(false)
  }

  function applySuggestions() {
    // Apply suggestions to form data
    const updatedData: ChecklistDataContainer = {
      ntpData: _.cloneDeep(props.editedChecklistData.ntpData) || convertSchemaToChecklistFormData(getTabSchema(ChecklistTab.NTP)) ,
      ptoData: _.cloneDeep(props.editedChecklistData.ptoData) || convertSchemaToChecklistFormData(getTabSchema(ChecklistTab.PTO)),
      icrData: _.cloneDeep(props.editedChecklistData.icrData) || convertSchemaToChecklistFormData(getTabSchema(ChecklistTab.ICR)),
    }

    for (const suggestion of suggestions) {
      if (suggestion.apply && suggestion.suggestedId) {
        const tabData: any =
          suggestion.tab === ChecklistTab.NTP
            ? updatedData.ntpData
            : suggestion.tab === ChecklistTab.PTO
              ? updatedData.ptoData
              : updatedData.icrData
        if (tabData && tabData[suggestion.sectionKey]) {
          tabData[suggestion.sectionKey][suggestion.key].record = suggestion.suggestedId
        }
      }
    }

    props.setEditedChecklistData(updatedData)
    toast.success(`Suggestions applied to checklist items!`, {
      position: "top-right",
      autoClose: 5000,
      theme: "light",
    })
    setShowSuggestionsDialog(false)
    setTimeout(() => {
      props.setEditedChecklistData(cloneDeep(updatedData))
    }, 100)
  }

  function groupSuggestions(suggestions: SuggestionItem[]) {
    const grouped: { [tab: string]: { [sectionKey: string]: SuggestionItem[] } } = {}
    suggestions.forEach((item) => {
      const tabName = ChecklistTab[item.tab]
      if (!grouped[tabName]) {
        grouped[tabName] = {}
      }
      if (!grouped[tabName][item.sectionKey]) {
        grouped[tabName][item.sectionKey] = []
      }
      grouped[tabName][item.sectionKey].push(item)
    })
    return grouped
  }

  const suggestionsGrouped = groupSuggestions(suggestions)

  function toggleTabItems(tabName: string, checked: boolean) {
    const updated = suggestions.map((item) => {
      const itemTabName = ChecklistTab[item.tab]
      if (itemTabName === tabName) {
        return { ...item, apply: checked }
      }
      return item
    })
    setSuggestions(updated)
  }

  function toggleSectionItems(tabName: string, sectionKey: string, checked: boolean) {
    const updated = suggestions.map((item) => {
      const itemTabName = ChecklistTab[item.tab]
      if (itemTabName === tabName && item.sectionKey === sectionKey) {
        return { ...item, apply: checked }
      }
      return item
    })
    setSuggestions(updated)
  }

  function isTabFullyChecked(tabName: string): boolean {
    const items = Object.values(suggestionsGrouped[tabName]).flat()
    return items.every((i) => i.apply)
  }

  function isSectionFullyChecked(tabName: string, sectionKey: string): boolean {
    const items = suggestionsGrouped[tabName][sectionKey]
    return items.every((i) => i.apply)
  }

  return (
    <>
      {lastUpdatedUser && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "2px" }}>
          Last updated by: {lastUpdatedUser.userName} at {moment(lastUpdatedUser.timestamp).format("lll")}
        </div>
      )}

      <div
        style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "12px" }}
      >
        <Button size="sm" color={"primary"} onClick={handleGenerateAllSuggestions}>
          Generate All Record Linking Suggestions
        </Button>
      </div>

      <Nav className="nav">
        {checklistTabs.map((_, index) => {
          const tab = indexToTab(index)
          return (
            <NavLink
              key={tab}
              className={`checklist__nav ${props.tab === tab ? "active" : ""}`}
              onClick={() => {
                props.setTab(tab)
              }}
            >
              {tab}
            </NavLink>
          )
        })}
      </Nav>
      <TabContent activeTab={props.tab}>
        {Object.keys(ChecklistTab).map((_, index) => {
          const tab = indexToTab(index)
          const checkListPercentage = checkListProgress(getTabSchema(tab), getTabData(tab))
          return (
            <TabPane key={index} tabId={tab} style={{ overflowY: "visible" }}>
              <div style={{ position: "relative", paddingTop: "5px", paddingBottom: "5px" }}>
                {confetti ? generateConfetti() : null}
                <Progress multi>
                  <Progress bar value={checkListPercentage}>
                    {checkListPercentage + "%"}
                  </Progress>
                  {checkListPercentage < 20 ? (
                    <Progress bar value={100} color={"secondary"}>
                      Checklist Progress
                    </Progress>
                  ) : null}
                </Progress>
              </div>
              {buildChecklistBody(tab)}
            </TabPane>
          )
        })}
      </TabContent>

      <BackdropDialog
        type={BackdropDialogFunction.Simple}
        title={t("process.headers.import_to_checklist")}
        simpleProps={{
          performText: t("process.messages.importing") ?? "",
        }}
        bodyText={t("process.messages.import_to_checklist") ?? ""}
        isOpen={isImportDialogOpen}
        onCancel={() => setIsImportDialogOpen(false)}
        onConfirm={async () => {
          await importToChecklist(props.code)
        }}
        onComplete={() => {
          props.refreshChecklistSchemas()
          setIsImportDialogOpen(false)
        }}
        inputProps={{
          performText: t("process.messages.importing") ?? "",
        }}
        displayText={`${t("process.messages.about_to_import")} ${props.state}`}
      />

      <Dialog open={showSuggestionsDialog} onOpenChange={setShowSuggestionsDialog}>
        <DialogContent className="tw-max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Apply Suggested Records</DialogTitle>
          </DialogHeader>
          {recordsLoading && (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8">
              <Spinner className="tw-mr-2 tw-mb-4" />
              <span>Loading records...</span>
            </div>
          )}
          {!recordsLoading && errorMessage && (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8">
              <Icon icon="error_outline" style={{ color: "red", fontSize: "24px" }} />
              <div style={{ marginTop: "10px", color: "red" }}>{errorMessage}</div>
            </div>
          )}
          {!recordsLoading && !errorMessage && showWarning && (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8">
              <Icon icon="warning" style={{ color: "orange", fontSize: "24px" }} />
              <div style={{ marginTop: "10px", color: "orange" }}>
                Fewer than 5 records available. This might cause poor suggestions.
              </div>
              <div style={{ marginTop: "15px" }}>
                <ShadButton variant="secondary" onClick={() => setShowSuggestionsDialog(false)}>
                  Cancel
                </ShadButton>{" "}
                <ShadButton variant="default" onClick={generateSuggestions}>
                  Proceed Anyway
                </ShadButton>
              </div>
            </div>
          )}

          {!recordsLoading && !errorMessage && !showWarning && isGeneratingSuggestions && (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8">
              <Spinner className="tw-mr-2 tw-mb-4" />
              <span>Generating suggestions...</span>
              <div className="tw-mt-2">
                Completed: {completedCount}/{totalCount} | No suggestion: {noSuggestionCount}
              </div>
            </div>
          )}

          {!recordsLoading && !errorMessage && !showWarning && !isGeneratingSuggestions && suggestions.length > 0 && (
            <div style={{ maxHeight: "400px", overflowY: "auto", marginTop: "10px" }}>
              <div className="tw-mb-2">
                Completed: {completedCount}/{totalCount} | No suggestion: {noSuggestionCount}
              </div>
              {Object.keys(suggestionsGrouped).map((tabName) => {
                const sections = suggestionsGrouped[tabName]
                const schema = getTabSchema(tabName as ChecklistTab)
                return (
                  <div key={tabName} style={{ marginBottom: "15px" }}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                      <Checkbox
                        checked={isTabFullyChecked(tabName)}
                        onCheckedChange={(checked) => toggleTabItems(tabName, !!checked)}
                      />
                      <h3 style={{ marginLeft: "10px" }}>{tabName}</h3>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      {[...Object.keys(sections)]
                        .sort((a, b) => a.localeCompare(b))
                        .map((sectionKey) => {
                          const items = sections[sectionKey]
                          const sectionSchema = schema?.properties[sectionKey]
                          return (
                            <div key={sectionKey} style={{ marginBottom: "10px" }}>
                              <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                <Checkbox
                                  checked={isSectionFullyChecked(tabName, sectionKey)}
                                  onCheckedChange={(checked) => toggleSectionItems(tabName, sectionKey, !!checked)}
                                />
                                <strong style={{ marginLeft: "10px" }}>{sectionSchema?.title || sectionKey}</strong>
                              </div>
                              <div style={{ marginLeft: "20px" }}>
                                {[...items]
                                  .sort(
                                    (a, b) =>
                                      sectionSchema["ui:order"].indexOf(a.key) -
                                      sectionSchema["ui:order"].indexOf(b.key),
                                  )
                                  .map((sug, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "5px",
                                        gap: "10px",
                                      }}
                                    >
                                      <Checkbox
                                        checked={sug.apply}
                                        onCheckedChange={(checked) => {
                                          const updated = [...suggestions]
                                          const idx = updated.findIndex(
                                            (u) =>
                                              u.tab === sug.tab &&
                                              u.sectionKey === sug.sectionKey &&
                                              u.key === sug.key &&
                                              u.title === sug.title,
                                          )
                                          if (idx !== -1) {
                                            updated[idx].apply = !!checked
                                            setSuggestions(updated)
                                          }
                                        }}
                                      />
                                      <div>
                                        <strong>
                                          {(sectionSchema.properties && sectionSchema.properties[sug.key]?.title) ||
                                            sug.title}
                                        </strong>
                                        : {sug.suggestedId ? sug.suggestedName : "No suggestion available"}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          <DialogFooter>
            {!recordsLoading && !errorMessage && !showWarning && !isGeneratingSuggestions && suggestions.length > 0 && (
              <>
                <DialogClose asChild>
                  <ShadButton variant="secondary">Cancel</ShadButton>
                </DialogClose>
                <ShadButton variant="default" onClick={applySuggestions}>
                  Apply
                </ShadButton>
              </>
            )}
            {errorMessage && (
              <>
                <DialogClose asChild>
                  <ShadButton variant="secondary">Close</ShadButton>
                </DialogClose>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

/**
 * The empty checklist prompt props.
 */
interface EmptyChecklistPromptProps {
  toggle: () => void
}

/**
 * The empty checklist prompt.
 * @param props - The props.
 * @constructor
 */
const EmptyChecklistPrompt = (props: EmptyChecklistPromptProps) => {
  return (
    <div className="Info">
      <h2 className="info-title">
        <Icon icon="info_outline" />
      </h2>
      <p className="info-message">{t("process.messages.empty_checklist_prompt")}</p>
      <Button variant="default" onClick={props.toggle}>
        {t("buttons.import")}
      </Button>
    </div>
  )
}
