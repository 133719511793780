import React, { Component } from "react"
import moment from "moment-timezone"
import jsprim from "jsprim"
import { Route, Redirect, Switch } from "react-router-dom"
import { Auth, Amplify } from "aws-amplify"
import i18n from "./i18n"
import "@aws-amplify/ui-react/styles.css"
import MFA from "./login/MFA"
import Settings from "./common/Settings"
import API from "./common/API"
import { withTranslation } from "react-i18next"
import EcosuiteComponent, { Error, Warning } from "./common/EcosuiteComponent"
import Schemas from "./common/Schemas"
import FinanceService from "@dashboard/finance/FinanceService"
import ProjectService from "./common/project/ProjectService"
import SearchUtils from "./common/utils/SearchUtils"
import { sortByAlpha, sortByDCSize, sortByRetainedEarnings } from "@common/utils/ProjectSortUtils"
import PowerUserUI from "./poweruser/PowerUserUI"
import ShellTerminal from "./terminal/ShellTerminal"
import Profile from "./profile/Profile"
import Header from "./Header"
import Dashboard from "./dashboard/Dashboard"
import Document from "./document/Document"
import Econode from "./econode/Econode"
import Admin from "./admin/Admin"
import logoRound from "./img/logo.png"
import "./App.css"
import "pretty-checkbox/dist/pretty-checkbox.min.css"
import Logger from "./common/Logger"
import * as Tracker from "./common/utils/TrackingUtils"
import SearchService from "./common/project/SearchService"
import PortfolioService from "./admin/settings/portfolio/PortfolioService"
import UserAdminService from "./admin/users/UserAdminService"
import AppConfig from "./AppConfig"
import EconodeUtils from "./econode/EconodeUtils"
import { Button } from "reactstrap"
import QGIS from "./qgis/QGIS"
import { Loader } from "@googlemaps/js-api-loader"
import { libraries } from "@dashboard/ProjectMapRoot"
import OrganizationService from "@admin/organizations/OrganizationAdminService"
import { getUserOrganizationApi } from "src/services/users"
import "./styles/global.css"
import "@cyntler/react-doc-viewer/dist/index.css"
import { isEmpty } from "lodash"

export const orgUXSettingsContext = React.createContext({ preferredEnergyUnits: "kW" })
export const SECURE_GROUPS = ["data-write", "solarnetwork", "user-admin"]
const { t } = i18n

Amplify.configure({
  Auth: {
    region: "us-east-1",
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
})

class App extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      refinements: [],
      selectedSortMethod: Settings.getSetting("project-sort-method", "alpha/desc"), // Default to alpha/desc
      portfolioId: Settings.getSetting("project-portfolioId"),
      selectedFilter: Settings.getSetting("project-filter"),
      selectedRefinements: Settings.getSetting("project-refinements", {}),
      disabledProjects: Settings.getSetting("project-projects", []),
      searchTerm: Settings.getSetting("search-term"),
      groups: [],
      orgUXSettings: { preferredEnergyUnits: "kW" },
    }

    this.load = this.load.bind(this)
    this.loadUser = this.loadUser.bind(this)
    this.loadProjects = this.loadProjects.bind(this)
    this.loadRefinements = this.loadRefinements.bind(this)
    this.loadMfaType = this.loadMfaType.bind(this)
    this.loadGoogleMaps = this.loadGoogleMaps.bind(this)

    this.selectPortfolio = this.selectPortfolio.bind(this)
    this.selectProject = this.selectProject.bind(this)
    this.selectFilter = this.selectFilter.bind(this)
    this.selectProjectSortMethod = this.selectProjectSortMethod.bind(this)
    this.toggleRefinement = this.toggleRefinement.bind(this)
    this.toggleProject = this.toggleProject.bind(this)
    this.search = this.search.bind(this)
    this.selectViewType = this.selectViewType.bind(this)
    this.signOut = this.signOut.bind(this)

    this.renderDashboard = this.renderDashboard.bind(this)
    this.renderAdmin = this.renderAdmin.bind(this)
    this.renderPowerUserUI = this.renderPowerUserUI.bind(this)
    this.renderProfile = this.renderProfile.bind(this)
    this.renderQGIS = this.renderQGIS.bind(this)
    this.renderTerminal = this.renderTerminal.bind(this)
    this.renderDocument = this.renderDocument.bind(this)

    this.renderHeader = this.renderHeader.bind(this)
    this.updateProject = this.updateProject.bind(this)
  }

  async loadUser() {
    Auth.currentSession().then(async (session) => {
      if (session.idToken.payload["custom:rootuser"] === "1") {
        if (window.location.pathname !== "/su-admin") {
          window.location.replace("/su-admin")
        }
      }
      let startDate = session.idToken.payload["custom:startDate"]
      let endDate = session.idToken.payload["custom:endDate"]
      const preventDownload = session.idToken.payload["custom:preventDownload"]
      const restrictProjects = session.idToken.payload["custom:restrictProjects"]

      const username = session.idToken.payload.email
      const userFirstName = session.idToken.payload["custom:firstName"]
      const userTypeId = session.idToken.payload["custom:userType"]
      const sub = session.idToken.payload.sub

      const groups = (await UserAdminService.getUserGroups({ id: sub })).groups.map((g) => g.id)

      const isEndDateExpired = moment().isAfter(moment(endDate))
      if (isEndDateExpired) {
        this.setState({
          error: "Your account has expired. Please contact support.",
          hasError: true,
        })
        Auth.signOut()
      } else {
        this.setState({
          userId: session.idToken.payload.sub,
          username,
          userFirstName,
          groups: groups || [],
          restrictions: {
            restrictProjects: restrictProjects,
            preventDownload: preventDownload,
            minimumDate: startDate,
            maximumDate: endDate,
          },
        })
        getUserOrganizationApi(session.idToken.payload.sub).then((data) => {
          if (data?.organization) {
            Tracker.install(data.organization)
          }
        })
      }
      this.loadUserType(userTypeId)
      if (groups && groups.includes("data-write")) {
        // We give the more immediate data a chance to load before warming the local cache
        setTimeout(Schemas.loadSchemas, 1000)
      }
      Tracker.setUser({ name: userFirstName, email: username })
    })

    const userInfo = await Auth.currentUserInfo()
    if (isEmpty(userInfo)) {
      Auth.signOut()
    }
    let timezone = userInfo && userInfo.attributes ? userInfo.attributes["custom:timezone"] : null
    moment.tz.setDefault(timezone)
    this.setState({ timezone: timezone })

    this.loadMfaType()
  }

  loadUserType(userTypeId) {
    UserAdminService.getUserType(userTypeId).then((response) => {
      const userType = {
        ...response.userType,
        defaultGroups: response.userType?.defaultGroups.filter((g) => g !== "rootuser"),
      }
      if (userType) {
        const appConfig = AppConfig.getConfigForApplication(userType, userType.application, this.state.groups)
        this.setState({ userType: userType, viewType: userType.application, moduleViews: appConfig?.modules })
      } else {
        this.setState({
          error: `User Type ${userTypeId} assigned to the profile isn't available`,
          hasError: true,
          userType: userType,
        })
      }
    })
  }

  componentDidMount() {
    super.componentDidMount()
    let params = new URLSearchParams(window.location.search)

    if (params.has("range")) {
      // If a range is specified in the URL we load that as the default range to view
      Settings.setSetting("rangeName", params.get("range"))
    }
    if (params.has("start")) {
      // If a range is specified in the URL we load that as the default range to view
      Settings.setSetting("start", params.get("start"))
    }
    if (params.has("end")) {
      // If a range is specified in the URL we load that as the default range to view
      Settings.setSetting("end", params.get("end"))
    }

    // On load if a project has been specified in the URL we select it
    let projectId = params.has("projectId") ? params.get("projectId") : null
    this.load(projectId)

    this.loadUser()
    this.loadGoogleMaps()

    setInterval(this.load, 300000) // Update the records evey 5 minutes to have the latest auto complete values
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    // Root component unmounted, clean-up any tracking suites we might have installed eariler.
    Tracker.uninstall()
  }

  async load(projectId) {
    Logger.debug("Loading latest data")
    this.loadProjects(projectId)
    this.loadPortfolios()
    this.loadRefinements()
    this.loadCashFlowTotals()
    this.loadOrgSettings()
  }

  async loadOrgSettings() {
    return OrganizationService.getorgUXSettings().then((settings) => {
      this.setState({
        orgUXSettings: settings,
      })
    })
  }

  async loadProjects(projectId) {
    return ProjectService.getProjects()
      .then((projects) => {
        const sortedProjects = this.selectProjectSortMethod(this.state.selectedSortMethod.split("/"), projects)
        if (!jsprim.deepEqual(this.state.projects, sortedProjects)) {
          Logger.info("Loading latest projects")
          // If we are reloading the projects we clear everytihng that has been cached
          API.clearCache()

          // On load if a project has been specified in the URL we select it
          let selectedProject = this.state.selectedProject

          // We prefer the currently selected project, since the selected project may
          // differ from the projectId given during the lifetime of the request
          if (selectedProject) {
            selectedProject = sortedProjects.find((project) => {
              return project.code === selectedProject.code
            })
          } else if (projectId) {
            selectedProject = sortedProjects.find((project) => {
              return project.code === projectId
            })
          }

          this.setState({
            projects: sortedProjects,
            selectedProject: selectedProject,
            loadTime: new Date(),
          })

          return sortedProjects
        }
      })
      .catch((err) => {
        Logger.error("Failed to load projects")
        Logger.error(err)
        if (!this.state.projects) {
          // If we don't have any projects loaded already the AMS won't function so we show an error
          this.setState({
            error: err,
            hasError: true,
          })
        }
      })
  }

  async loadPortfolios() {
    return PortfolioService.getPortfolios()
      .then((portfolios) => {
        if (!jsprim.deepEqual(this.state.portfolios, portfolios)) {
          Logger.info("Loading latest portfolios")
          var portfolio = null
          if (this.state.portfolioId) {
            portfolio = portfolios.find((portfolio) => portfolio.id === this.state.portfolioId)
          }
          this.setStateIfMounted({
            portfolios: portfolios,
            portfolio: portfolio,
          })
        }
      })
      .catch((err) => {
        Logger.error("Failed to load portfolios")
        Logger.error(err)
      })
  }

  async loadRefinements() {
    SearchService.getRefinements()
      .then(({ refinements, autoCompletes }) => {
        if (
          !jsprim.deepEqual(this.state.refinements, refinements) ||
          !jsprim.deepEqual(this.state.autoCompletes, autoCompletes)
        ) {
          Logger.info("Loading latest refinements")
          // Remove any refinement selections that no longer exist
          const selectedRefinements = this.state.selectedRefinements
          Object.keys(selectedRefinements).forEach((selectedRefinementKey) => {
            if (refinements[selectedRefinementKey]) {
              selectedRefinements[selectedRefinementKey] = selectedRefinements[selectedRefinementKey].filter(
                (selectedRefinementValue) => {
                  return refinements[selectedRefinementKey].includes(selectedRefinementValue)
                },
              )
            } else {
              Logger.warning(`Removing unsupported refinement ${selectedRefinementKey}`)
              delete selectedRefinements[selectedRefinementKey]
            }
          })

          this.setStateIfMounted(
            {
              refinements: refinements,
              selectedRefinements: selectedRefinements,
              autoCompletes: autoCompletes,
            },
            () => {
              Settings.setSetting("project-refinements", selectedRefinements)
            },
          )
        }
      })
      .catch((err) => {
        Logger.error("Failed to load search refinements")
        Logger.error(err)
      })
  }

  async loadCashFlowTotals() {
    return FinanceService.getCashFlowTotals()
      .then(({ projectTotals }) => {
        if (!jsprim.deepEqual(this.state.projectTotals, projectTotals)) {
          this.setStateIfMounted({ cashflowTotals: projectTotals })
        }
      })
      .catch((err) => {
        Logger.error("Failed to load Cashflow Totals")
        Logger.error(err)
      })
  }

  selectFilter(filter) {
    this.setState({ selectedFilter: filter, disabledProjects: [], selectedProject: null, portfolio: null }, () => {
      Settings.setSetting("project-filter", filter)
      Settings.setSetting("project-portfolioId", null)
      Tracker.emit({ name: "Project Filter: " + filter })
    })
  }

  toggleRefinement(name, value) {
    const selectedRefinements = this.state.selectedRefinements
    if (value) {
      if (selectedRefinements[name]) {
        if (selectedRefinements[name].includes(value)) {
          selectedRefinements[name] = selectedRefinements[name].filter((refinement) => refinement !== value)
        } else {
          selectedRefinements[name].push(value)
        }
      } else {
        selectedRefinements[name] = [value]
      }
    } else {
      delete selectedRefinements[name]
    }

    this.setState({ selectedRefinements: selectedRefinements, selectedProject: null }, () => {
      Settings.setSetting("project-refinements", selectedRefinements)
      Tracker.emit({ name: "Project Refinements: " + selectedRefinements })
    })
  }

  toggleProject(projectId) {
    var disabledProjects = this.state.disabledProjects
    if (projectId) {
      if (disabledProjects.includes(projectId)) {
        disabledProjects = disabledProjects.filter((selectedProjectId) => selectedProjectId !== projectId)
      } else {
        disabledProjects.push(projectId)
      }
    } else {
      disabledProjects = []
    }

    this.setState({ disabledProjects: disabledProjects, selectedProject: null }, () => {
      Settings.setSetting("project-projects", disabledProjects)
      Tracker.emit({ name: "Selected Projects: " + disabledProjects })
    })
  }

  search(searchTerm) {
    this.setState({ searchTerm: searchTerm, disabledProjects: [], selectedProject: null }, () => {
      Settings.setSetting("search-term", searchTerm)
      Tracker.emit({ name: "Search Term: " + searchTerm })
    })
  }

  selectPortfolio(portfolioId) {
    const portfolio = this.state.portfolios.find((portfolio) => portfolio.id === portfolioId)
    Settings.setSetting("project-portfolioId", portfolioId)
    Settings.setSetting("project-filter", null)
    this.setState({ portfolio: portfolio, selectedFilter: null, disabledProjects: [], selectedProject: null })
    if (portfolioId) {
      Tracker.emit({ name: "Portfolio: " + portfolioId })
    } else {
      Tracker.emit({ name: "All Projects" })
    }
  }

  selectProject(project) {
    if (this.state.selectedProject && project && this.state.selectedProject.code === project.code) {
      // The selected project has been deselected
      if (this.arePortfoliosEnabled()) {
        // Allow projects to be deselected
        this.setState({ selectedProject: null })
        Tracker.emit({ name: "Overview" })
      } else {
        // Don't allow projects to be deselected when not viewing a portfolio
      }
    } else {
      this.setState({ selectedProject: project })
      if (project) {
        Tracker.emit({ name: "Project: " + project.code })
      } else {
        Tracker.emit({ name: "Overview" })
      }
    }
  }

  selectViewType(viewType) {
    viewType = viewType ? viewType : "ams"
    const appConfig = AppConfig.getConfigForApplication(this.state.userType, viewType, this.state.groups)
    this.setStateIfMounted({ viewType: viewType, moduleViews: appConfig.modules })
    Tracker.emit({ name: `App: ${viewType || "ams"}` })
  }

  getFilteredProjects() {
    var projects = this.state.projects
    if (projects && this.getSelectedPortfolio()) {
      projects = projects.filter((project) => this.getSelectedPortfolio().projects.includes(project.code))
    }
    if (this.state.selectedFilter || this.state.selectedRefinements) {
      projects = SearchUtils.filterProjects(
        projects,
        this.state.autoCompletes,
        this.state.selectedFilter,
        this.state.selectedRefinements,
      )
    }
    if (this.state.searchTerm && projects) {
      projects = projects.filter((project) => {
        const searchTerm = this.state.searchTerm.toLowerCase()
        if (
          project.name.toLowerCase().includes(searchTerm) ||
          project.state.toLowerCase().includes(searchTerm) ||
          project.address.toLowerCase().includes(searchTerm)
        ) {
          return true
        } else if (project.code.includes(searchTerm.toUpperCase())) {
          return true
        } else {
          return Object.values(project.sites).some((site) => {
            if (site.name.toLowerCase().includes(searchTerm) || site.address.toLowerCase().includes(searchTerm)) {
              return true
            } else {
              return site.code.includes(searchTerm.toUpperCase())
            }
          })
        }
      })
    }
    return projects
  }

  getSelectedProjects() {
    var projects = this.getFilteredProjects()
    if (this.state.disabledProjects.length && projects) {
      projects = projects.filter((project) => !this.state.disabledProjects.includes(project.code))
    }
    return projects
  }

  /**
   * @param {string[]} param0
   * @param {Project[] | undefined} inputProjects
   * @returns `Project[] | undefined` - returns sorted inputProjects or sorts current project state
   */
  selectProjectSortMethod([sortMethod, sortDirection], inputProjects) {
    // Make a copy of projects to prevent mutation of original
    const projects = [...(inputProjects ?? this.state.projects)]

    if (sortMethod === "alpha") {
      sortByAlpha(projects)
    } else if (sortMethod === "dcSize") {
      sortByDCSize(projects)
    } else if (sortMethod === "retainedEarnings") {
      sortByRetainedEarnings(projects, this.state.cashflowTotals ?? {})
    }

    if (sortDirection === "asc") {
      projects.reverse()
    }

    // Save the current sort method to sort ProjectService response
    this.setState({ selectedSortMethod: `${sortMethod}/${sortDirection}` })
    Settings.setSetting("project-sort-method", `${sortMethod}/${sortDirection}`)

    if (inputProjects) {
      return projects
    } else {
      this.setState({ projects })
    }
  }

  getSelectedPortfolio() {
    if (this.state.portfolio) {
      return this.state.portfolio
    } else if (
      this.state.portfolios &&
      this.state.portfolios.length === 1 &&
      this.state.restrictions.restrictProjects === "portfolios"
    ) {
      return this.state.portfolios[0] // If there's only 1 portfolio select it
    }
  }

  getSelectedProject(selectedProjects) {
    if (this.state.selectedProject) {
      return this.state.selectedProject
    } else if (this.arePortfoliosEnabled()) {
      // Return null to show the portfolio
      return null
    } else {
      // When a portfolio isn't select we return the first project to ensure that a project is available
      return selectedProjects[0]
    }
  }

  arePortfoliosEnabled() {
    // We ensure the user isn't restricted to projects and the projects are less than the virtual portfolio limit
    return this.state.restrictions.restrictProjects !== "yes"
  }

  loadMfaType() {
    Auth.currentAuthenticatedUser().then((user) => {
      // Will retrieve the current mfa type from cache
      Auth.getPreferredMFA(user, {
        // Optional, by default is false.
        // If set to true, it will get the MFA type from server side instead of from local cache.
        bypassCache: false,
      }).then((mfaType) => {
        this.setState({ mfaType: mfaType })
      })
    })
  }

  loadGoogleMaps() {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
      version: "weekly",
      libraries,
    })
    loader.load()
  }

  isMfaRequired() {
    if (this.state.mfaType && this.state.mfaType === "NOMFA" && this.state.groups) {
      return SECURE_GROUPS.some((secureGroup) => {
        return this.state.groups.indexOf(secureGroup) >= 0
      })
    }
    return false
  }

  getTitle(title) {
    return title + " - Ecosuite"
  }

  checkVersion() {
    fetch("/manifest.json").then((response) => {
      return response.json().then((manifest) => {
        if (this.props.version < manifest.version) {
          Logger.info(`Updating version ${this.props.version} to ${manifest.version}`)
        }
      })
    })
  }

  updateProject(newProjectData) {
    this.setState({ selectedProject: newProjectData })
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <Error error={this.state.error} />
          <div className="Error">
            <Button onClick={this.signOut}>Sign Out</Button>
          </div>
        </div>
      )
    }
    if (this.state.mfaType && this.state.userType !== undefined) {
      if (this.isMfaRequired()) {
        return <MFA loadMfaType={this.loadMfaType} />
      } else {
        return (
          <>
            <div className={`App App-stage-${process.env.REACT_APP_STAGE}`}>
              {this.renderHeader()}
              {this.state.hasError ? (
                this.renderError()
              ) : (
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to={Settings.getSetting("default-view", "/energy")} />}
                  />
                  <Route
                    exact
                    path="/dashboard"
                    render={() => <Redirect to={Settings.getSetting("default-view", "/energy")} />}
                  />
                  <Route path="/admin" component={this.renderAdmin} />
                  <Route path="/profile" component={this.renderProfile} />
                  <Route path="/qgis" component={this.renderQGIS} />
                  <Route path="/terminal" component={this.renderTerminal} />
                  <Route path="/power-dashboard" component={this.renderPowerUserUI} />
                  <Route path="/document" component={this.renderDocument} />
                  <Route render={this.renderDashboard} />
                </Switch>
              )}
            </div>
          </>
        )
      }
    } else {
      return <Loading />
    }
  }

  renderHeader() {
    // Some display is dependent on the timezone for the user being loaded so we wait until it has been
    if (!Object.prototype.hasOwnProperty.call(this.state, "timezone")) {
      Logger.debug("Waiting for timezone to load")
      return null
    }

    return (
      <Header
        userId={this.state.userId}
        username={this.state.username}
        userFirstName={this.state.userFirstName}
        userType={this.state.userType}
        viewType={this.state.viewType}
        groups={this.state.groups}
        timezone={this.state.timezone}
        version={this.props.version}
        actions={{ selectViewType: this.selectViewType }}
      />
    )
  }

  renderPowerUserUI() {
    this.checkVersion()
    document.title = this.getTitle("Power Dashboard")

    if (this.state.groups.includes("power-user")) {
      // Some display is dependent on the timezone for the user being loaded so we wait until it has been
      if (!Object.prototype.hasOwnProperty.call(this.state, "timezone")) {
        Logger.debug("Waiting for timezone to load")
        return <Loading />
      }
      return <PowerUserUI restrictions={this.state.restrictions} projects={this.getFilteredProjects()} />
    } else {
      return <Warning title={`${t("labels.notice")}`} message={`${t("errors.unauthorised_access")}`} />
    }
  }

  renderProfile() {
    this.checkVersion()
    document.title = this.getTitle("User Settings")

    return <Profile userId={this.state.userId} userName={this.state.username} groups={this.state.groups} />
  }

  renderQGIS() {
    this.checkVersion()
    document.title = this.getTitle("QGIS")

    return <QGIS />
  }

  renderTerminal() {
    this.checkVersion()
    document.title = this.getTitle("Terminal")

    if (this.state.groups.includes("power-user")) {
      return <ShellTerminal />
    } else {
      return <Warning title={`${t("labels.notice")}`} message={`${t("errors.unauthorised_access")}`} />
    }
  }

  renderDocument() {
    this.checkVersion()
    document.title = this.getTitle("Document")

    if (this.state.groups.includes("legal")) {
      return <Document />
    } else {
      return <Warning title={`${t("labels.notice")}`} message={`${t("errors.unauthorised_access")}`} />
    }
  }

  renderDashboard({ location }) {
    this.checkVersion()
    document.title = this.getTitle("Ecosuite Dashboard")

    Settings.setSetting("default-view", location.pathname)

    let actions = {
      selectFilter: this.selectFilter,
      selectProjectSortMethod: this.selectProjectSortMethod,
      toggleRefinement: this.toggleRefinement,
      toggleProject: this.toggleProject,
      search: this.search,
      selectPortfolio: this.selectPortfolio,
      selectProject: this.selectProject,
      loadProjects: this.loadProjects,
    }

    // Some display is dependent on the timezone for the user being loaded so we wait until it has been
    if (!Object.prototype.hasOwnProperty.call(this.state, "timezone")) {
      Logger.debug("Waiting for timezone to load")
      return <Loading />
    }

    const filteredProjects = this.getFilteredProjects()
    const selectedProjects = this.getSelectedProjects()
    if (this.state.viewType === "econode") {
      document.title = this.getTitle("Econode Dashboard")

      const allProjects = EconodeUtils.filterEconodeProjects(this.state.projects ? this.state.projects : [])
      const econodeProjects = EconodeUtils.filterEconodeProjects(filteredProjects)
      const econodeSelectedProjects = EconodeUtils.filterEconodeProjects(selectedProjects)
      return (
        <Econode
          refinements={this.state.refinements}
          autoCompletes={this.state.autoCompletes}
          selectedFilter={this.state.selectedFilter}
          selectedRefinements={this.state.selectedRefinements}
          disabledProjects={this.state.disabledProjects}
          searchTerm={this.state.searchTerm}
          portfolios={this.state.portfolios}
          portfolio={this.getSelectedPortfolio()}
          allProjects={allProjects}
          projects={econodeProjects}
          selectedProjects={econodeSelectedProjects}
          selectedProject={this.getSelectedProject(econodeSelectedProjects)}
          groups={this.state.groups}
          loadTime={this.state.loadTime}
          actions={actions}
          version={this.props.version}
          moduleViews={this.state.moduleViews}
          restrictions={this.state.restrictions}
          userId={this.state.userId}
          portfoliosEnabled={this.arePortfoliosEnabled()}
          updateProject={this.updateProject}
        />
      )
    } else {
      return (
        <orgUXSettingsContext.Provider value={this.state.orgUXSettings}>
          <Dashboard
            refinements={this.state.refinements}
            autoCompletes={this.state.autoCompletes}
            selectedFilter={this.state.selectedFilter}
            selectedRefinements={this.state.selectedRefinements}
            disabledProjects={this.state.disabledProjects}
            searchTerm={this.state.searchTerm}
            portfolios={this.state.portfolios}
            portfolio={this.getSelectedPortfolio()}
            allProjects={this.state.projects}
            filteredProjects={filteredProjects}
            selectedProjects={selectedProjects}
            selectedProject={this.getSelectedProject(selectedProjects)}
            groups={this.state.groups}
            loadTime={this.state.loadTime}
            actions={actions}
            version={this.props.version}
            moduleViews={this.state.moduleViews}
            restrictions={{
              ...this.state.restrictions,
              // Add `IRR controls` to restrictions from the userType.
              ...this.state.userType?.modules?.ams?.finance?.irrControls,
              ...{ checklistControls: this.state.userType?.modules?.ams?.process?.checklistControls },
            }}
            userId={this.state.userId}
            username={this.state.username}
            portfoliosEnabled={this.arePortfoliosEnabled()}
            orgUXSettings={this.state.orgUXSettings}
          />
        </orgUXSettingsContext.Provider>
      )
    }
  }

  renderAdmin() {
    document.title = this.getTitle("Admin")

    return (
      <Admin
        currentUserId={this.state.userId}
        groups={this.state.groups}
        portfolios={this.state.portfolios}
        projects={this.state.projects}
        project={this.state.selectedProject}
        onProjectChange={this.loadProjects}
        username={this.state.username}
        timezone={this.state.timezone}
        version={this.props.version}
      />
    )
  }

  onLogin(loginState) {
    this.setState({ verified: loginState })
  }

  signOut() {
    Auth.signOut().then(() => {
      window.location.href = "/"
    })
  }
}

class Loading extends Component {
  render() {
    return (
      <div className="App-loading">
        <img src={logoRound} className="App-logo" alt="logo" />
        <div>{t("labels.loading")}</div>
      </div>
    )
  }
}

const AppComponent = withTranslation()(App)
export default AppComponent
